import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlusIcon from '@mui/icons-material/Add';
import OpenIcon from '@mui/icons-material/OpenInNew';
import { CircularProgress, Alert, Grid, Button, IconButton, TextField, Badge } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

import { useMutation, useQuery } from '@apollo/client';
import {
  OFFERVERSIONS_LIST_QUERY,
  REFETCH_OFFER_QUERIES,
  EVICT_OFFER_QUERIES,
  OFFERVERSION_DELETE_MUTATION,
  OFFER_SEND_REMINDER_CLIENT_MUTATION
} from './gql';
import SimpleTable from 'components/table/SimpleTable';
import CustomTabs from 'components/Tabs';
import { EOfferSource, EOfferStatus, EOfferVersionStatus, EOfferVersionType, ListWlOfferVersionsQuery } from '__generated__/graphql';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import SimpleAccordion from 'components/SimpleAccordion';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { FormatDateTime, FormatDateRange } from 'components/DateTime';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/ForwardToInbox';
import { filterSelector } from 'helper/filter';
import { RedirectError } from 'pages/error';
import { userSelector } from 'helper/security';

const StyledBadge = ({ count, showCount = true }: { count: number, showCount?: boolean }) => (
  <Badge
    badgeContent={showCount ? count : undefined}
    variant={showCount ? "standard" : "dot"}
    sx={{
      '& .MuiBadge-badge': {
        backgroundColor: '#ac2a6e', // Correct Seminargo color
        color: 'white',
        ...(showCount ? {} : {
          height: '12px',
          width: '12px',
          borderRadius: '6px',
          minWidth: '12px',
          padding: 0
        })
      }
    }}
  />
);

// Add this style near the top with other constants
const hotelLinkStyle = { textDecoration: 'none', color: '#ac2a6e' };
const bookingIdStyle = { textDecoration: 'none', fontWeight: 'bold' };

export default function OffersList(props: { offerSource: EOfferSource }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [deleteOfferVersionId, setDeleteOfferVersionId] = React.useState<number | null>(null);
  const [deleteReason, setDeleteReason] = React.useState('');

  // Add this filter function to reuse across the component
  const filterOffersBySearchQuery = (offers: ListWlOfferVersionsQuery['listWlOfferVersions']) => {
    return offers.filter(o => 
      o.hotel.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
      o.offer.refCode.toLowerCase().includes(searchQuery.toLowerCase()) || 
      (o.client?.company && o.client.company.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  const _formatDateRange = (row: ListWlOfferVersionsQuery['listWlOfferVersions'][0]) =>
    <FormatDateRange from={props.offerSource === EOfferSource.WIDGET ? row.input?.startDate : row.inputLister?.startDate} to={props.offerSource === EOfferSource.WIDGET ? row.input?.endDate : row.inputLister?.endDate} />

  const queryOpenForInvoiceSemApproval = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.INVOICE],
      versionStatus: [EOfferVersionStatus.IV_WAITFORSEMAPPROVAL],
      offerStatus: [EOfferStatus.INVOICE]
    },
    skip: props.offerSource !== EOfferSource.LISTER || !user.isSeminargo
  });

  const queryOpenForInvoice = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.INVOICE],
      versionStatus: [EOfferVersionStatus.IV_WAITFORHOTEL],
      offerStatus: [EOfferStatus.INVOICE]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenForInvoiceApproval = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.INVOICE],
      versionStatus: [EOfferVersionStatus.IV_WAITFORSEMAPPROVAL, EOfferVersionStatus.IV_WAITFORCLIENTAPPROVAL],
      offerStatus: [EOfferStatus.INVOICE]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenToApprove = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER],
      versionStatus: [EOfferVersionStatus.WAITFORHOTELAPPROVAL],
      offerStatus: [EOfferStatus.CLIENTVERIFICATION, EOfferStatus.PENDING]
    },
  });

  const queryOpenToReserve = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER],
      versionStatus: [EOfferVersionStatus.WAITFORHOTELRESERVATION],
      offerStatus: [EOfferStatus.CLIENTVERIFICATION, EOfferStatus.PENDING]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenToApproveCR = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.EDITING],
      versionStatus: [EOfferVersionStatus.CR_WAITFORHOTELAPPROVAL],
      offerStatus: [EOfferStatus.APPROVED]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenToEditCR = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.EDITING],
      versionStatus: [EOfferVersionStatus.CR_EDITING],
      offerStatus: [EOfferStatus.APPROVED]
    },
    skip: props.offerSource !== EOfferSource.LISTER
  });

  const queryOpenWaitForClient = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER],
      versionStatus: [EOfferVersionStatus.WAITFORCLIENTAPPROVAL],
      offerStatus: [EOfferStatus.CLIENTVERIFICATION, EOfferStatus.PENDING]
    },
  });

  const queryApproved = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.APPROVED],
      offerStatus: [EOfferStatus.APPROVED, EOfferStatus.ACTIVE]
    },
  });

  const queryRejected = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER, EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.REJECTED_CLIENT, EOfferVersionStatus.REJECTED_HOTEL],
      offerStatus: [EOfferStatus.REJECTED]
    },
  });

  const queryActive = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.APPROVED],
      offerStatus: [EOfferStatus.ACTIVE]
    },
  });

  const queryClosed = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      //versionType: [EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.CLOSED, EOfferVersionStatus.IV_APPROVED],
      offerStatus: [EOfferStatus.CLOSED, EOfferStatus.INVOICE]
    },
  });

  const queryDeleted = useQuery(OFFERVERSIONS_LIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      spaceId: (filter && filter.spaceId) || null,
      hotelId: (filter && filter.hotelId) || null,
      offerSource: props.offerSource,
      versionType: [EOfferVersionType.OFFER, EOfferVersionType.RESERVATION_CONFIRMATION],
      versionStatus: [EOfferVersionStatus.DELETED],
      offerStatus: [EOfferStatus.DELETED]
    },
  });
  const queries = [queryOpenForInvoiceSemApproval, queryOpenForInvoice, queryOpenForInvoiceApproval, queryOpenToApprove, queryOpenToReserve, queryOpenToApproveCR, queryOpenToEditCR, queryOpenWaitForClient, queryApproved, queryRejected, queryActive, queryClosed, queryDeleted]

  const [deleteOfferVersion] = useMutation(OFFERVERSION_DELETE_MUTATION);
  const [sendOfferReminderEmailClient] = useMutation(OFFER_SEND_REMINDER_CLIENT_MUTATION);

  const loading = queries.find(q => q.loading)
  const error = queries.find(q => q.error) ? queries.find(q => q.error)!.error : null

  if (loading) return <CircularProgress />;
  else if (error) return <RedirectError err={error} />;

  // Use the filter function for all filtered queries
  const filteredQueryApproved = filterOffersBySearchQuery(queryApproved.data!.listWlOfferVersions);
  const filteredQueryRejected = filterOffersBySearchQuery(queryRejected.data!.listWlOfferVersions);
  const filteredQueryActive = filterOffersBySearchQuery(queryActive.data!.listWlOfferVersions);
  const filteredQueryClosed = filterOffersBySearchQuery(queryClosed.data!.listWlOfferVersions);
  const filteredQueryDeleted = filterOffersBySearchQuery(queryDeleted.data!.listWlOfferVersions);
  const filteredQueryOpenWaitForClient = filterOffersBySearchQuery(queryOpenWaitForClient.data!.listWlOfferVersions);
  const filteredQueryOpenToApprove = filterOffersBySearchQuery(queryOpenToApprove.data!.listWlOfferVersions);
  
  // Conditionally filter queries that might not exist based on offerSource
  const filteredQueryOpenToReserve = props.offerSource === EOfferSource.LISTER ? 
    filterOffersBySearchQuery(queryOpenToReserve.data!.listWlOfferVersions) : [];
  const filteredQueryOpenToApproveCR = props.offerSource === EOfferSource.LISTER ? 
    filterOffersBySearchQuery(queryOpenToApproveCR.data!.listWlOfferVersions) : [];
  const filteredQueryOpenToEditCR = props.offerSource === EOfferSource.LISTER ? 
    filterOffersBySearchQuery(queryOpenToEditCR.data!.listWlOfferVersions) : [];
  const filteredQueryOpenForInvoice = props.offerSource === EOfferSource.LISTER ? 
    filterOffersBySearchQuery(queryOpenForInvoice.data!.listWlOfferVersions) : [];
  const filteredQueryOpenForInvoiceSemApproval = props.offerSource === EOfferSource.LISTER && user.isSeminargo ? 
    filterOffersBySearchQuery(queryOpenForInvoiceSemApproval.data!.listWlOfferVersions) : [];

  const handleRowClick = (row: any) => {
    if (row.status === EOfferVersionStatus.IV_WAITFORHOTEL ||
      row.status === EOfferVersionStatus.IV_WAITFORSEMAPPROVAL ||
      row.status === EOfferVersionStatus.IV_WAITFORCLIENTAPPROVAL ||
      row.status === EOfferVersionStatus.IV_APPROVED) {
      navigate(`/invoices/${row.offer.id}/${row.id}`);
    } else {
      navigate(`/offers/${row.offer.id}/${row.id}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>{i18next.t(`offers-list-page-title-${props.offerSource}`)}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {(props.offerSource === EOfferSource.WIDGET || user.isSeminargo) &&
            <Button
              variant="contained"
              sx={{ marginBottom: 2 }}
              onClick={async () => {
                if (props.offerSource === EOfferSource.WIDGET) navigate('/offers/_create_widget');
                if (props.offerSource === EOfferSource.LISTER) navigate('/offers/_create_lister');
              }}
            >
              <PlusIcon /> Neues Angebot erstellen
            </Button>
          }
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <TextField
            label="Suche"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button
            sx={{ marginBottom: 2 }}
            onClick={() => {
              queries.forEach(q => q.refetch())
            }}
          >
            <RefreshIcon /> Aktualisieren
          </Button>
        </Grid>
      </Grid>
      {props.offerSource === EOfferSource.LISTER && user.isSeminargo && (
        <Grid item xs={12} sx={{ my: 2 }}>
          <SimpleAccordion
            header={
              <Grid container alignItems="center" spacing={2} sx={{ '& > .MuiGrid-item:last-child': { marginLeft: 1 } }}>
                <Grid item>{i18next.t('offers-list-seminargo-confirmation')}</Grid>
                <Grid item>
                  <StyledBadge count={filteredQueryOpenForInvoiceSemApproval.length} />
                </Grid>
              </Grid>
            }
          >
            <SimpleTable
              headers={[
                i18next.t('offers-list-header-createdat'),
                i18next.t('offers-list-header-refcode'),
                i18next.t('offers-list-header-hotel'),
                i18next.t('offers-list-header-from-to'),
                i18next.t('offers-list-header-client'),
                i18next.t('offers-list-header-status'),
                '',
              ]}
              rows={
                filteredQueryOpenForInvoiceSemApproval.map(row => [
                  <FormatDateTime date={row.createdAt} />,
                  <Link to={`/invoices/${row.offer.id}/${row.id}`} style={bookingIdStyle}>
                    {row.offer.refCode}
                  </Link>,
                  <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>
                    {row.hotel.name}
                  </Link>,
                  _formatDateRange(row),
                  row.client?.company,
                  i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                  '', // Alignment placeholder
                ])
              }
              onRowClick={(index) => {
                handleRowClick(filteredQueryOpenForInvoiceSemApproval[index]);
              }}
            />
          </SimpleAccordion>
        </Grid>
      )}
      <Grid item xs={12}>
        {/* Open/To Process Section */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <SimpleAccordion
            autoOpen
            header={
              <Grid container alignItems="center" spacing={2} sx={{ '& > .MuiGrid-item:last-child': { marginLeft: 1 } }}>
                <Grid item>{i18next.t('offers-list-open')}</Grid>
                <Grid item>
                  <StyledBadge
                    count={
                      (props.offerSource === EOfferSource.LISTER ? filteredQueryOpenToReserve.length : 0) +
                      filteredQueryOpenToApprove.length +
                      (props.offerSource === EOfferSource.LISTER ? filteredQueryOpenToApproveCR.length : 0) +
                      (props.offerSource === EOfferSource.LISTER ? filteredQueryOpenToEditCR.length : 0) +
                      (props.offerSource === EOfferSource.LISTER ? filteredQueryOpenForInvoice.length : 0)
                    }
                  />
                </Grid>
              </Grid>
            }
          >
            <SimpleTable
              headers={[
                i18next.t('offers-list-header-createdat'),
                i18next.t('offers-list-header-refcode'),
                i18next.t('offers-list-header-hotel'),
                i18next.t('offers-list-header-from-to'),
                i18next.t('offers-list-header-client'),
                i18next.t('offers-list-header-status'),
                '',
              ]}
              rows={[
                // Hotel Reservation rows (LISTER only)
                ...(props.offerSource === EOfferSource.LISTER
                  ? filteredQueryOpenToReserve.map(row => [
                      <FormatDateTime date={row.createdAt} />,
                      <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>
                        {row.offer.refCode}
                      </Link>,
                      <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>
                        {row.hotel.name}
                      </Link>,
                      _formatDateRange(row),
                      row.client?.company,
                      i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                      '', // Placeholder for alignment
                    ])
                  : []),

                // Hotel Approval rows (ALL)
                ...filteredQueryOpenToApprove.map(row => [
                  <FormatDateTime date={row.createdAt} />,
                  <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>
                    {row.offer.refCode}
                  </Link>,
                  <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>
                    {row.hotel.name}
                  </Link>,
                  _formatDateRange(row),
                  row.client?.company,
                  i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                  '', // Placeholder for alignment
                ]),

                // CR Approval rows (LISTER only)
                ...(props.offerSource === EOfferSource.LISTER
                  ? filteredQueryOpenToApproveCR.map(row => [
                      <FormatDateTime date={row.createdAt} />,
                      <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>
                        {row.offer.refCode}
                      </Link>,
                      <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>
                        {row.hotel.name}
                      </Link>,
                      _formatDateRange(row),
                      row.client?.company,
                      i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                      '', // Placeholder for alignment
                    ])
                  : []),

                // CR Editing rows (LISTER only)
                ...(props.offerSource === EOfferSource.LISTER
                  ? filteredQueryOpenToEditCR.map(row => [
                      <FormatDateTime date={row.createdAt} />,
                      <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>
                        {row.offer.refCode}
                      </Link>,
                      <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>
                        {row.hotel.name}
                      </Link>,
                      _formatDateRange(row),
                      row.client?.company,
                      i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                      '', // Placeholder for alignment
                    ])
                  : []),

                // Invoice Approval rows (LISTER only)
                ...(props.offerSource === EOfferSource.LISTER
                  ? filteredQueryOpenForInvoice.map(row => [
                      <FormatDateTime date={row.createdAt} />,
                      <Link to={`/invoices/${row.offer.id}/${row.id}`} style={bookingIdStyle}>
                        {row.offer.refCode}
                      </Link>,
                      <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>
                        {row.hotel.name}
                      </Link>,
                      _formatDateRange(row),
                      row.client?.company,
                      i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                      '', // Placeholder for alignment
                    ])
                  : [])
              ]}
              onRowClick={(index) => {
                const allRows = [
                  ...filteredQueryOpenToReserve,
                  ...filteredQueryOpenToApprove,
                  ...filteredQueryOpenToApproveCR,
                  ...filteredQueryOpenToEditCR,
                  ...filteredQueryOpenForInvoice
                ];
                handleRowClick(allRows[index]);
              }}
            />
          </SimpleAccordion>
        </Grid>

        {/* Offers Section */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <SimpleAccordion
            header={
              <Grid container alignItems="center" spacing={2} sx={{ '& > .MuiGrid-item:last-child': { marginLeft: 1 } }}>
                <Grid item>{String(i18next.t('offers-list-offers'))}</Grid>
                <Grid item>
                  <StyledBadge count={filteredQueryOpenWaitForClient.length} />
                </Grid>
              </Grid>
            }
          >
            <SimpleTable
              headers={[
                i18next.t('offers-list-header-createdat'),
                i18next.t('offers-list-header-refcode'),
                i18next.t('offers-list-header-hotel'),
                i18next.t('offers-list-header-from-to'),
                i18next.t('offers-list-header-client'),
                i18next.t('offers-list-header-status'),
                '',
              ]}
              rows={
                filteredQueryOpenWaitForClient.map(row => [
                  <FormatDateTime date={row.createdAt} />,
                  <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>{row.offer.refCode}</Link>,
                  <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>{row.hotel.name}</Link>,
                  _formatDateRange(row),
                  row.client?.company,
                  i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                  '', // Placeholder for alignment
                ])
              }
              onRowClick={(index) => {
                handleRowClick(filteredQueryOpenWaitForClient[index]);
              }}
            />
          </SimpleAccordion>
        </Grid>

        {/* Approved Section */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <SimpleAccordion
            header={
              <Grid container alignItems="center" spacing={2} sx={{ '& > .MuiGrid-item:last-child': { marginLeft: 1 } }}>
                <Grid item>{String(i18next.t('offers-list-reservations'))}</Grid>
                <Grid item><StyledBadge count={filteredQueryApproved.length} /></Grid>
              </Grid>
            }
          >
            <SimpleTable
              headers={[
                i18next.t('offers-list-header-createdat'),
                i18next.t('offers-list-header-refcode'),
                i18next.t('offers-list-header-hotel'),
                i18next.t('offers-list-header-from-to'),
                i18next.t('offers-list-header-client'),
                i18next.t('offers-list-header-status'),
                '',
              ].filter(Boolean)}
              rows={
                filteredQueryApproved.map((row, index) => [
                  <FormatDateTime date={row.createdAt} />,
                  <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>{row.offer.refCode}</Link>,
                  <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>{row.hotel.name}</Link>,
                  _formatDateRange(row),
                  row.client?.company,
                  i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                  '', // Empty cell for alignment
                ])
              }
              onRowClick={(index) => {
                handleRowClick(filteredQueryApproved[index]);
              }}
            />
          </SimpleAccordion>
        </Grid>

        {/* Archive Section */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <SimpleAccordion
            header={
              <Grid container alignItems="center" spacing={2} sx={{ '& > .MuiGrid-item:last-child': { marginLeft: 1 } }}>
                <Grid item>{String(i18next.t('offers-list-archive'))}</Grid>
                <Grid item>
                  <StyledBadge count={filteredQueryClosed.length + filteredQueryDeleted.length + filteredQueryRejected.length} />
                </Grid>
              </Grid>
            }
          >
            <SimpleTable
              headers={[
                i18next.t('offers-list-header-createdat'),
                i18next.t('offers-list-header-refcode'),
                i18next.t('offers-list-header-hotel'),
                i18next.t('offers-list-header-from-to'),
                i18next.t('offers-list-header-client'),
                i18next.t('offers-list-header-status'),
                i18next.t('offers-list-header-delete-reason'),
              ]}
              rows={[
                ...filteredQueryRejected.map((row, index) => [
                  <FormatDateTime date={row.createdAt} />,
                  <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>{row.offer.refCode}</Link>,
                  <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>{row.hotel.name}</Link>,
                  _formatDateRange(row),
                  row.client?.company,
                  i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                  row.offer.deleteReason || '',
                ]),
                ...filteredQueryClosed.map((row, index) => [
                  <FormatDateTime date={row.createdAt} />,
                  <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>{row.offer.refCode}</Link>,
                  <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>{row.hotel.name}</Link>,
                  _formatDateRange(row),
                  row.client?.company,
                  i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                  row.offer.deleteReason || '',
                ]),
                ...filteredQueryDeleted.map((row, index) => [
                  <FormatDateTime date={row.createdAt} />,
                  <Link to={`/offers/${row.offer.id}/${row.id}`} style={bookingIdStyle}>{row.offer.refCode}</Link>,
                  <Link to={`/settings/hotels/${row.hotel.id}`} style={hotelLinkStyle}>{row.hotel.name}</Link>,
                  _formatDateRange(row),
                  row.client?.company,
                  i18next.t(`enums-EOfferVersionStatus-${row.status}`),
                  row.offer.deleteReason,
                ])
              ]}
              onRowClick={(index) => {
                const allRows = [
                  ...filteredQueryRejected,
                  ...filteredQueryClosed,
                  ...filteredQueryDeleted
                ];
                handleRowClick(allRows[index]);
              }}
            />
          </SimpleAccordion>
        </Grid>
      </Grid>

      <ConfirmationDialog
        title={i18next.t('offerconfirm-delete-title')}
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        keepOpenOnConfirm={true}
        onConfirm={async () => {
          if (deleteReason.length === 0) {
            return;
          } else {
            try {
              await deleteOfferVersion({
                variables: {
                  offerVersionId: deleteOfferVersionId!,
                  deleteReason,
                },
                update: cache => EVICT_OFFER_QUERIES(cache),
                awaitRefetchQueries: true,
                refetchQueries: REFETCH_OFFER_QUERIES(),
              });
              setDeleteReason('');
              setShowDeleteDialog(false);
              dispatchMessage(dispatch, i18next.t('offerconfirm-delete-success'));
              queries.forEach(q => q.refetch())
            } catch (err) {
              dispatchException(dispatch, err);
            }
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {i18next.t('offerconfirm-delete-text')}
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={deleteReason.length === 0}
              fullWidth
              label={i18next.t('offerconfirm-delete-reason')}
              value={deleteReason}
              onChange={e => setDeleteReason(e.target.value)}
            />
          </Grid>
        </Grid>
      </ConfirmationDialog>
    </>
  );
}
