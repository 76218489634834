import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

interface LabeledFrameProps {
  label: string;
  variant?: Variant;
  children: React.ReactNode;
  className?: string;
  sx?: SxProps<Theme>;
}

export function LabeledFrame({ label, variant, children, className, sx }: LabeledFrameProps) {
  return (
    <Box
      className={className}
      sx={{
        position: 'relative',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'divider',
        borderRadius: '4px',
        padding: '24px 8px 8px 8px',
        ...sx,
      }}
    >
      <Typography
        variant={variant ||  'caption'}
        sx={{
          position: 'absolute',
          top: 1,
          left: '10px',
          backgroundColor: '#fff',
          padding: '0 5px',
          transform: 'translateY(-50%)'
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
} 