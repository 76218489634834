import { gql } from '__generated__/gql';
import { ApolloCache } from '@apollo/client';

import { EVICT_FACILITIES_QUERIES, EVICT_PRODUCTS_QUERIES, EVICT_PRODUCTBUNDLES_QUERIES } from '../products/gql';

export const PRICELIST_VALIDATION_FRAGMENT = gql(`
  fragment PriceListValidationFull on PriceListValidationOutput {
    isComplete
    productsWithoutPrice {
      ...ProductListShort
    }
    productsWithoutPriceComponents {
      product { id name sku space { ...SpaceShort } }
      selector { id name }
    }
    facilitiesWithoutPrice {
      ...FacilityListShort
    }
    facilitiesWithoutPriceComponents {
      facility { id name sku space { ...SpaceShort } }
      selector { id name }
    }
    bundlesWithoutPrice {
      ...ProductBundleListShort
    }
    bundlesWithoutPriceComponents {
      bundle { id name sku space { ...SpaceShort } }
      selector { id name }
    }
  }
`);

export const PRICE_SHORT_FRAGMENT = gql(`
  fragment ProductPriceShort on ProductPriceListOutput {
    id
    price
    selector {
      id
      name
      isBasePrice
      priceList {
        id
        name
        isPublished
        version
        currency
      }
    }
  }
`);

export const PRICELISTS_LIST_SHORT_FRAGMENT = gql(`
  fragment PriceListListShort on PriceListListOutput {
    id
    name
    isDefault
    isPricesNet
    isPublished
    version
    refCode
    currency
    validity {
      validFrom
      validTo
    }
    forLister
    forWidget
    hotel {
      id
      name
    }
    space { ...SpaceShort }
    basePriceSelector {
      id
      name
    }
  }
`);

export const PRICELISTS_LIST_VIEW_FRAGMENT = gql(`
  fragment PriceListListView on PriceListListOutput {
    id
    name
    isDefault
    isPricesNet
    isPublished
    version
    refCode
    currency
    validity {
      validFrom
      validTo
    }
    parent { id name refCode }
    children { id name refCode }
    rules {
      rule
      rate
      rounding
      product { id name sku space { ...SpaceShort } }
      facility { id name sku space { ...SpaceShort } }
      bundle { id name sku space { ...SpaceShort } }
    }
    forLister
    forWidget
    hotel {
      id
      name
    }
    space { ...SpaceShort }
    basePriceSelector {
      id
      name
    }
    validation {
      ...PriceListValidationFull
    }
  }
`);

export const PRICELISTS_LIST_QUERY = gql(`
  query ListPriceLists($spaceId: Int, $hotelId: Int, $refCode: String, $isPublished: Boolean, $skip: Int, $take: Int ) {
    listPriceLists(spaceId: $spaceId, hotelId: $hotelId, refCode: $refCode, isPublished: $isPublished, skip: $skip, take: $take) {
      ...PriceListListShort
    }
  }
`);

export const PRICELIST_VIEW_QUERY = gql(`
  query ViewPriceList($id: Int!) {
    viewPriceList(id: $id) {
      ...PriceListListView
    }
  }
`);

export const CREATE_PRICELIST_MUTATION = gql(`
  mutation CreatePriceList($spaceId: Int!, $data: PriceListCreateInput!) {
    createPriceList(spaceId: $spaceId, data: $data) {
      ...PriceListListView
    }
  }
`);

export const UPDATE_PRICELIST_MUTATION = gql(`
  mutation UpdatePriceList($id: Int!, $data: PriceListInput!) {
    updatePriceList(id: $id, data: $data) {
      ...PriceListListView
    }
  }
`);

export const DELETE_PRICELIST_MUTATION = gql(`
  mutation DeletePriceList($id: Int!) {
    deletePriceList(id: $id)
  }
`);

export const COPY_PRICELIST_MUTATION = gql(`
  mutation CopyPriceList($id: Int!, $spaceId: Int!) {
    copyPriceList(id: $id, spaceId: $spaceId) {
      ...PriceListListView
    }
  }
`);

export const EVICT_PRICELISTS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listPriceLists' });
  EVICT_PRODUCTS_QUERIES(cache);
  EVICT_PRODUCTBUNDLES_QUERIES(cache);
  EVICT_FACILITIES_QUERIES(cache);
  cache.gc();
};

export const REFETCH_PRICELISTS_QUERIES = (priceListId?: number | undefined) => [
  ...(priceListId
    ? [
        {
          query: PRICELIST_VIEW_QUERY,
          variables: { id: priceListId },
        },
      ]
    : []),
];

export const PRICESELECTOR_LIST_SHORT_FRAGMENT = gql(`
  fragment PriceSelectorListShort on PriceSelectorListOutput {
    id
    name
    isBasePrice
    maxGuestCount
    maxItemCount
    minGuestCount
    minItemCount
    validFrom
    validTo
    weekdays
    priceList {
      id
      name
      currency
    }
    space { ...SpaceShort }
  }
`);

export const PRICESELECTOR_LIST_VIEW_FRAGMENT = gql(`
  fragment PriceSelectorListView on PriceSelectorListOutput {
    id
    isBasePrice
    maxGuestCount
    maxItemCount
    minGuestCount
    minItemCount
    name
    validFrom
    validTo
    weekdays
    priceList {
      id
      isDefault
      isPricesNet
      isPublished
      name
      validity {
        id
        validFrom
        validTo
      }
      version
      refCode
      currency
      space { ...SpaceShort }
    }
    space { ...SpaceShort }
    prices {
      id
      price
      bundlePriceFromProduct
      bundle {
        id
        sku
        name
      }
      facility {
        id
        sku
        name
      }
      product {
        id
        sku
        name
      }
      components {
        id
        price
        taxType { id name }
      }
    }
  }
`);

export const PRICESELECTOR_LIST_QUERY = gql(`
  query ListPriceSelectors($priceListId: Int!, $skip: Int, $take: Int) {
    listPriceSelectors(priceListId: $priceListId, skip: $skip, take: $take) {
      ...PriceSelectorListShort
    }
  }
`);

export const PRICESELECTOR_VIEW_QUERY = gql(`
  query ViewPriceSelector($id: Int!) {
    viewPriceSelector(id: $id) {
      ...PriceSelectorListView
    }
  }
`);

export const PRICESELECTORBASE_VIEW_QUERY = gql(`
  query ViewBasePriceSelector($priceListId: Int!) {
    viewBasePriceSelector(priceListId: $priceListId) {
      ...PriceSelectorListView
    }
  }
`);

export const CREATE_PRICESELECTOR_MUTATION = gql(`
  mutation CreatePriceSelector($data: PriceSelectorCreateInput!) {
    createPriceSelector(data: $data) {
      ...PriceSelectorListView
    }
  }
`);

export const UPDATE_PRICESELECTOR_MUTATION = gql(`
  mutation UpdatePriceSelector($id: Int!, $data: PriceSelectorInput!) {
    updatePriceSelector(id: $id, data: $data) {
      ...PriceSelectorListView
    }
  }
`);

export const DELETE_PRICESELECTOR_MUTATION = gql(`
  mutation DeletePriceSelector($id: Int!) {
    deletePriceSelector(id: $id)
  }
`);

export const COPY_PRICESELECTOR_MUTATION = gql(`
  mutation CopyPriceSelector($id: Int!) {
    copyPriceSelector(id: $id) {
      ...PriceSelectorListView
    }
  }
`);

export const EVICT_PRICESELECTORS_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listPriceSelectors' });
  EVICT_PRICELISTS_QUERIES(cache);
};

export const REFETCH_PRICESELECTORS_QUERIES = (priceListId: number, priceSelectorId?: number | undefined) => [
  {
    query: PRICELIST_VIEW_QUERY,
    variables: { id: priceListId },
  },
  {
    query: PRICESELECTORBASE_VIEW_QUERY,
    variables: { priceListId: priceListId },
  },
  ...(priceSelectorId
    ? [
        {
          query: PRICESELECTOR_VIEW_QUERY,
          variables: { id: priceSelectorId },
        },
      ]
    : []),
  ...(priceListId
    ? [
        {
          query: PRICESELECTORBASE_VIEW_QUERY,
          variables: { priceListId: priceListId },
        },
      ]
    : []),
];

export const TAXTYPES_LIST_SHORT_FRAGMENT = gql(`
  fragment TaxTypeListShort on TaxTypeListOutput {
    id
    name
    sequence
    lookupCode
    space { ...SpaceShort }
  }
`);

export const TAXTYPES_LIST_VIEW_FRAGMENT = gql(`
  fragment TaxTypeListView on TaxTypeListOutput {
    id
    sequence
    name
    lookupCode
    rates {
      id
      rate
      name
      country
      isReverseCharge
      validFrom
      validTo
    }
    space { ...SpaceShort }
  }
`);

export const TAXTYPES_LIST_QUERY = gql(`
  query ListTaxTypes($spaceId: Int) {
    listTaxTypes(spaceId: $spaceId) {
      ...TaxTypeListShort
    }
  }
`);

export const TAXTYPES_LIST_FULL_QUERY = gql(`
  query ListFullTaxTypes($spaceId: Int) {
    listTaxTypes(spaceId: $spaceId) {
      ...TaxTypeListView
    }
  }
`);

export const TAXTYPE_VIEW_QUERY = gql(`
  query ViewTaxType($id: Int!) {
    viewTaxType(id: $id) {
      ...TaxTypeListView
    }
  }
`);

export const CREATE_TAXTYPE_MUTATION = gql(`
  mutation CreateTaxType($spaceId: Int, $data: TaxTypeCreateInput!) {
    createTaxType(spaceId: $spaceId, data: $data) {
      ...TaxTypeListView
    }
  }
`);

export const UPDATE_TAXTYPE_MUTATION = gql(`
  mutation UpdateTaxType($id: Int!, $data: TaxTypeInput!) {
    updateTaxType(id: $id, data: $data) {
      ...TaxTypeListView
    }
  }
`);

export const DELETE_TAXTYPE_MUTATION = gql(`
  mutation DeleteTaxType($id: Int!) {
    deleteTaxType(id: $id)
  }
`);

export const EVICT_TAXTYPES_QUERIES = (cache: ApolloCache<any>) => {
  cache.evict({ fieldName: 'listTaxTypes' });
  cache.gc();
};

export const REFETCH_TAXTYPES_QUERIES = (taxTypeId?: number | undefined) => [
  ...(taxTypeId
    ? [
        {
          query: TAXTYPE_VIEW_QUERY,
          variables: { id: taxTypeId },
        },
      ]
    : []),
];

export const IMPORT_PRICES_MUTATION = gql(`
  mutation ImportPriceList($spaceId: Int!, $hotelId: Int, $name: String, $version: String, $jsonText: String, $excelBase64: String) {
    importPriceList(spaceId: $spaceId, hotelId: $hotelId, name: $name, version: $version, jsonText: $jsonText, excelBase64: $excelBase64) {
      priceList {
        ...PriceListListShort
      }
      validation {
        ...PriceListValidationFull
      }
    }
  }
`);

export const EXPORT_PRICES_MUTATION = gql(`
  mutation ExportPriceList($priceListId: Int!) {
    exportPriceList(priceListId: $priceListId)
  }
`);
