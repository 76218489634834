import React from 'react';
import { formatPercentage as formatPercentageShared } from 'semshared/utils/format';

interface PercentageProps {
  percentage: number;
  precision?: number;
}

export function formatPercentage(percentage: number, precision: number = 0) {
  return formatPercentageShared(percentage, 'de-AT', precision)
}

export default function Percentage(props: PercentageProps) {
  const { percentage, precision } = props;

  return <>{formatPercentage(percentage, precision)}</>;
}
