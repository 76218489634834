import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Grid, CircularProgress } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { FACILITIES_LIST_QUERY } from './gql';

import { FacilityCopyButton, FacilityDeleteButton } from './facility';

export default function FacilitiesList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(FACILITIES_LIST_QUERY);

  return (
    <>
      <Helmet>
        <title>{i18next.t('facilities-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            filterKey={`${JSON.stringify(filter)}`}
            refetchPage={(skip, take) =>
              query({
                variables: {
                  spaceId: (filter && filter.spaceId) || null,
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            rowsErr={error}
            headers={[
              i18next.t('facilities-list-header-facility'),
              i18next.t('facilities-list-header-sku'),
              i18next.t('facilities-list-header-recurring'),
              i18next.t('facilities-list-header-isaddon'),
              ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
              '',
            ].filter(f => f !== undefined)}
            rows={(data?.listFacilities || []).map((row, index) =>
              [
                <Link to={`/products/facilities/${row.id}`}>
                  {row.name}
                  {row.extRefCode && ` (${row.extRefCode})`}
                </Link>,
                row.sku,
                `${i18next.t('enums-EFacilityPayment-' + row.recurring)}`,
                row.isAddon ? <CheckIcon /> : '',
                ...(user.isSingleSpace ? [] : [row.space.name]),
                <ButtonGroup>
                  <FacilityCopyButton id={row.id} spaceId={row.space.id} icon />
                  <FacilityDeleteButton id={row.id} spaceId={row.space.id} isSystem={row.isSystem} icon />
                </ButtonGroup>,
              ].filter(f => f !== undefined),
            )}
          />
        </Grid>
        {hasAnyAdminSpace(user) && (
          <Grid item xs={12}>
            <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/products/facilities/_create')}>
              {i18next.t('facilities-list-add')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
