import React from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';
import i18next from 'i18next';

import Users from './users';
import User, { UserCreate } from './user';
import Spaces from './spaces';
import Space from './space';
import NewSpace from './newspace';

import Me from './me';

import { userSelector } from 'helper/security';

export default function SecurityIndex() {
  const location = useLocation();
  const user = userSelector();

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          {location.pathname !== '/settings/security/me' && (
            <Tabs
              value={location.pathname === '/settings/security' ? '/settings/security/users' : location.pathname.split('/').slice(0, 4).join('/')}
            >
              <Tab value="/settings/security/users" label={i18next.t('security-tabs-header-users')} component={Link} to="/settings/security/users" />
              {user && (user.isAdmin || user.isRoot) && (
                <Tab
                  value="/settings/security/spaces"
                  label={i18next.t('security-tabs-header-spaces')}
                  component={Link}
                  to="/settings/security/spaces"
                />
              )}
            </Tabs>
          )}

          <Box p={2}>
            <Routes>
              <Route path="me" element={<Me />} />
              <Route index element={<Users />} />
              <Route path="users/_create" element={<UserCreate />} />
              <Route path="users/:id" Component={() => <User id={parseInt(useParams().id!)} />} />
              <Route path="users" Component={() => <Users />} />
              {user && (user.isAdmin || user.isRoot) && (
                <>
                  <Route path="spaces/_create" element={<NewSpace />} />
                  <Route path="spaces/:id" Component={() => <Space id={parseInt(useParams().id!)} />} />
                  <Route path="spaces" Component={() => <Spaces />} />
                </>
              )}
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
