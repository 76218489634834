import React from 'react';
import { Controller } from 'react-hook-form';
import Input from '@mui/material/TextField';
import { FormFileUploadInputProps } from './FormInputProps';

export type FormFile = {
  name: string;
  size: number;
  type: string;
  content: string | ArrayBuffer;
};

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const FormInputFileUpload = ({ name, control, label, accept, binary }: FormFileUploadInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          helperText={error ? error.message : undefined}
          onChange={e => {
            const formfile = (e.target as any).files[0];
            if (!formfile) return;

            const asBinary = binary(formfile.name, formfile.type);

            const reader = new FileReader();
            reader.onload = async eo => {
              onChange({
                name: formfile.name,
                size: formfile.size,
                type: formfile.type,
                content: asBinary ? arrayBufferToBase64((eo.target as any).result) : (eo.target as any).result,
              });
            };
            if (asBinary) {
              reader.readAsArrayBuffer(formfile);
            } else {
              reader.readAsText(formfile);
            }
          }}
          fullWidth
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          type="file"
          inputProps={{
            accept,
          }}
        />
      )}
    />
  );
};
