import React, { useEffect } from 'react';
import { Container, Paper, Toolbar, Tabs, Tab, Box, Grid, Button, Divider } from '@mui/material';
import { useLocation, Link, Routes, Route, Outlet, useParams } from 'react-router-dom';

import { userSelector, hasAnyAdminSpace } from 'helper/security';
import i18next from 'i18next';
import ClientsList from './clients';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';
import { useApolloClient, useQuery } from '@apollo/client';
import { FormInputText } from 'components/form/FormInputText';
import CompaniesList from './companies';
import { BOOKINGS_LIST_SEARCH_QUERY, HOTELS_LIST_SEARCH_QUERY, PUBLIC_CLIENT_LIST_SEARCH_QUERY, PUBLIC_COMPANIES_LIST_SEARCH_QUERY } from './gql';
import { HL_PublicClientOutput, HL_PublicCompanyOutput, HLBookingListOutput, HotelListOutput } from '__generated__/graphql';
import { Search } from '@mui/icons-material';
import BookingsList from './bookings';
import HotelsList from './hotels';

const validationSchema = yup.object().shape({
  query: yup.string().required().min(3, 'Suchbegriff muss mindestens 3 Zeichen haben').label(i18next.t('search-quicksearch-label')),
});


export default function SearchIndex() {
  const location = useLocation();
  const user = userSelector()!;

  type BookingFormType = yup.InferType<typeof validationSchema>;

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: {
      query: '',
    }
  });

  const [searchQuery, setSearchQuery] = React.useState('');

  const { data: dataClients, loading: loadingClients, error: errorClients } = useQuery(PUBLIC_CLIENT_LIST_SEARCH_QUERY, {
    variables: { query: searchQuery },
  });

  const { data: dataCompanies, loading: loadingCompanies, error: errorCompanies } = useQuery(PUBLIC_COMPANIES_LIST_SEARCH_QUERY, {
    variables: { query: searchQuery },
  });

  const { data: dataBookings, loading: loadingBookings, error: errorBookings } = useQuery(BOOKINGS_LIST_SEARCH_QUERY, {
    variables: { query: searchQuery },
  });

  const { data: dataHotels, loading: loadingHotels, error: errorHotels } = useQuery(HOTELS_LIST_SEARCH_QUERY, {
    variables: { query: searchQuery },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <form onSubmit={handleSubmit((data) => setSearchQuery(data.query))}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
            <FormInputText control={control} name="query" label={i18next.t('search-quicksearch-label')} />
            </Grid>
            <Grid item xs={3}>
            <Button type="submit" variant="contained" color="primary"><Search /> Suchen</Button>
            </Grid>
            </Grid>
            </form>
          </Box>
          <Divider />
          <Tabs value={location.pathname === '/search' ? '/search/companies' : location.pathname.split('/').slice(0, 3).join('/')}>
            <Tab value="/search/companies" label={`${i18next.t('search-tabs-header-companies')} (${dataCompanies ? dataCompanies.searchPublicCompanies.length : '?'})`} component={Link} to="/search/companies" />
            <Tab value="/search/clients" label={`${i18next.t('search-tabs-header-clients')} (${dataClients ? dataClients.searchPublicClients.length : '?'})`} component={Link} to="/search/clients" />
            <Tab value="/search/bookings" label={`${i18next.t('search-tabs-header-bookings')} (${dataBookings ? dataBookings.searchBookings.length : '?'})`} component={Link} to="/search/bookings" />
            <Tab value="/search/hotels" label={`${i18next.t('search-tabs-header-hotels')} (${dataHotels ? dataHotels.searchHotels.length : '?'})`} component={Link} to="/search/hotels" />
          </Tabs>

          <Box p={2}>
            <Routes>
              <Route index element={<ClientsList clients={dataClients?.searchPublicClients as HL_PublicClientOutput[] || []} />} />
              <Route path="bookings" element={<BookingsList bookings={dataBookings?.searchBookings as HLBookingListOutput[] || []} />} />
              <Route path="clients" element={<ClientsList clients={dataClients?.searchPublicClients as HL_PublicClientOutput[] || []} />} />
              <Route path="companies" element={<CompaniesList companies={dataCompanies?.searchPublicCompanies as HL_PublicCompanyOutput[] || []} />} />
              <Route path="hotels" element={<HotelsList hotels={dataHotels?.searchHotels as HotelListOutput[] || []} />} />
            </Routes>

            <Outlet />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
