import React from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Alert, Grid, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

import { useLazyQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';

import PagedTable from 'components/table/PagedTable';
import { FormatDateTime } from 'components/DateTime';
import FileUploadButton from 'components/FileUploadButton';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { exportToFile } from 'helper/download';
import { filterSelector } from 'helper/filter';
import { canEditAdminSpaceId, userSelector } from 'helper/security';

import {
  CONTENTMEDIAS_LIST_QUERY,
  UPLOAD_CONTENTMEDIA_MUTATION,
  DELETE_CONTENTMEDIA_MUTATION,
  IMPORT_CONTENTMEDIA_MUTATION,
  EXPORT_CONTENTMEDIA_MUTATION,
  EVICT_CONTENTMEDIA_QUERIES,
  REFETCH_CONTENTMEDIA_QUERIES,
} from '../gql';

export default function ContentMediasList() {
  const dispatch = useDispatch();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(CONTENTMEDIAS_LIST_QUERY);

  const [uploadMutation, { loading: uploadLoading }] = useMutation(UPLOAD_CONTENTMEDIA_MUTATION);
  const [deleteMutation, { loading: deleteLoading }] = useMutation(DELETE_CONTENTMEDIA_MUTATION);
  const [importMutationFunction, { loading: importLoading }] = useMutation(IMPORT_CONTENTMEDIA_MUTATION);
  const [exportMutateFunction, { loading: exportLoading }] = useMutation(EXPORT_CONTENTMEDIA_MUTATION);

  const importToSpaceId = (filter && filter.spaceId) || user.space?.id;
  const canUpload = user.isSeminargo || (importToSpaceId && canEditAdminSpaceId(user, importToSpaceId)) || user.isAdmin;
  const canDelete = (spaceId: number) => user.isSeminargo || canEditAdminSpaceId(user, spaceId);

  return (
    <>
      <Helmet>
        <title>{i18next.t('contentmedias-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            filterKey={`${JSON.stringify(filter)}`}
            refetchPage={(skip, take) =>
              query({
                variables: {
                  spaceId: (filter && filter.spaceId) || null,
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            rowsErr={error}
            headers={[
              i18next.t('contentmedias-list-header-name'),
              ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
              i18next.t('contentmedias-list-header-mimetype'),
              i18next.t('contentmedias-list-header-updatedat'),
              '',
              ...(!canDelete ? [] : ['']),
            ]}
            rows={(data?.listContentMedia || []).map(row => [
              row.name,
              ...(user.isSingleSpace ? [] : [row.space.name]),
              row.mimeType,
              <FormatDateTime date={row.updatedAt} />,
              <>{row.previewUrl && <img src={row.previewUrl} width={150} />}</>,
              ...(!canDelete(row.space.id)
                ? []
                : [
                    <ConfirmationButton
                      confirmationQuestion={i18next.t('contentmedia-confirm-delete')}
                      confirmationTitle={i18next.t('contentmedia-confirm-delete-title')}
                      icon={true}
                      onConfirm={async () => {
                        try {
                          const res = await deleteMutation({
                            variables: {
                              id: row.id,
                            },
                            update: cache => EVICT_CONTENTMEDIA_QUERIES(cache),
                            awaitRefetchQueries: true,
                            refetchQueries: REFETCH_CONTENTMEDIA_QUERIES(),
                          });
                          dispatchMessage(dispatch, i18next.t('contentmedia-deleted'));
                        } catch (err) {
                          dispatchException(dispatch, err);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </ConfirmationButton>,
                  ]),
            ])}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            color="secondary"
            startIcon={exportLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
            disabled={exportLoading}
            onClick={async () => {
              try {
                const res = await exportMutateFunction({
                  variables: {
                    spaceId: (filter && filter.spaceId) || null,
                  },
                });
                if (res.data?.exportMedias) {
                  exportToFile(res.data?.exportMedias, 'application/json', 'seminargo-medias.json');
                }
              } catch (err) {
                dispatchException(dispatch, err);
              }
            }}
          >
            {i18next.t('contentmedias-export-run')}
          </Button>
          {canUpload && importToSpaceId && (
            <FileUploadButton
              name="importMedia"
              disabled={importLoading}
              startIcon={importLoading ? <CircularProgress size={24} /> : <UploadIcon />}
              binary={(name, type) => (type === 'application/json' ? false : true)}
              onChange={async (name, type, content) => {
                try {
                  if (type === 'application/json') {
                    const res = await importMutationFunction({
                      variables: {
                        spaceId: importToSpaceId,
                        jsonText: content,
                      },
                      update: cache => EVICT_CONTENTMEDIA_QUERIES(cache),
                      awaitRefetchQueries: true,
                      refetchQueries: REFETCH_CONTENTMEDIA_QUERIES(),
                    });
                  } else {
                    const res = await uploadMutation({
                      variables: {
                        spaceId: importToSpaceId,
                        filename: name,
                        mimeType: type,
                        base64: content,
                      },
                      update: cache => EVICT_CONTENTMEDIA_QUERIES(cache),
                      awaitRefetchQueries: true,
                      refetchQueries: REFETCH_CONTENTMEDIA_QUERIES(),
                    });
                  }
                  dispatchMessage(dispatch, i18next.t('contentmedias-import-ready'));
                } catch (err) {
                  dispatchException(dispatch, err);
                }
              }}
            >
              {i18next.t('contentmedias-import-run')}
            </FileUploadButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}
