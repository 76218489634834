export interface IUser {
  id: number;
  email: string;
  name: string;
  isAdmin: boolean;
  hasLister: boolean;
  hasWidget: boolean;
  isRoot: boolean;
  isSeminargo: boolean;
  isSingleSpace: boolean;
  isSingleAdminSpace: boolean;
  space: ISpace | null;
  memberships: IUserMembership[];
  spaces: ISpace[];
  hotels: IHotel[];
}

export interface IUserMembership {
  isAdmin: boolean;
  space?: ISpace | null | undefined;
}

export interface ISpace {
  id: number;
  name: string;
  country: string;
  parent?: ISpace | null | undefined;
  children?: ISpace[] | null | undefined;
}

export interface IBoundToSpace {
  space?: ISpace | null | undefined;
}

export interface IHotel {
  id: number;
  name: string;
  extRefCode?: string | null;
  space: ISpace;
}

export interface IHotelFilter {
  spaceId: number | null;
  hotelId: number | null;
}

export enum EPriceDisplayMode {
  NET = 'net',
  GROSS = 'gross',
}
