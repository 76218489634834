import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Button, IconButton, Grid, CircularProgress, Tooltip, Select, Typography } from '@mui/material';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { showValidationAlert, hintValidationAlert } from 'helper/form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { SimpleDropdown, buildMenuItemsFromOptions } from 'components/form/FormInputDropdown';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import CustomTabs from 'components/Tabs';
import SimpleTable from 'components/table/SimpleTable';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';
import { GroupedSpaceMultiCheckboxInput, buildGroupedSpaceSelectionOptions } from 'components/security/GroupedSpaceSelectionInput';
import { SpaceSelectionInput, buildSpaceSelectionOptions } from 'components/security/SpaceSelectionInput';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpaceId, filterForSelectableRecords } from 'helper/security';
import { filterSelector } from 'helper/filter';

import ProductDetailsFlat, {
  ProductDetailsFlatContentSchema,
  ProductDetailsFlatFromTypeToSchema,
  ProductDetailsFlatFromSchemaToType,
} from '../../content/products/productdetailsflat_embedded';

import {
  SERVICETYPE_VIEW_QUERY,
  PRODUCTS_LIST_QUERY,
  PRODUCTBUNDLES_LIST_QUERY,
  FACILITIES_LIST_QUERY,
  UPDATE_SERVICETYPE_MUTATION,
  CREATE_SERVICETYPE_MUTATION,
  DELETE_SERVICETYPE_MUTATION,
  SET_PRODUCTDETAILS_MUTATION,
  COPY_SERVICETYPE_MUTATION,
  REFETCH_SERVICETYPES_QUERIES,
  EVICT_SERVICETYPES_QUERIES,
} from '../gql';
import { HOTEL_LIST_QUERY } from '../../settings/gql';
import { PRICELISTS_LIST_QUERY } from '../../prices/gql';
import {
  ServiceTypeListOutput,
  HotelListOutput,
  PriceListListOutput,
  ProductListOutput,
  ProductBundleListOutput,
  FacilityListOutput,
  ViewServiceTypeQuery,
  ListProductsQuery,
  ListFacilitiesQuery,
  ListProductBundlesQuery,
  ListHotelsDropdownQuery,
  ListHotelsQuery,
  ListPriceListsQuery,
} from '__generated__/graphql';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { RedirectError } from 'pages/error';
import { formatDocumentTitle } from 'helper/usedocumenttitle';

interface ServiceTypeProps {
  id: number;
}
interface ServiceTypeCreateProps {}
interface ServiceTypeFormProps {
  data: NonNullable<ViewServiceTypeQuery['viewServiceType']>;
  products: ListProductsQuery['listProducts'];
  facilities: ListFacilitiesQuery['listFacilities'];
  bundles: ListProductBundlesQuery['listProductBundles'];
  hotels: ListHotelsQuery['listHotels'];
  priceLists: ListPriceListsQuery['listPriceLists'];
}

const productsSchema = yup
  .object()
  .shape({
    productId: yup.number().nullable(),
    productBundleId: yup.number().nullable(),
    isForDayVisitor: yup.boolean().required(),
    isForOvernight: yup.boolean().required(),
    isForDeparture: yup.boolean().required(),
    isForSemidayVisitor: yup.boolean().required(),
    isForSemidayOvernight: yup.boolean().required(),
    isForSemidayDeparture: yup.boolean().required(),
    isForPrevday: yup.boolean().required(),
    isForNextday: yup.boolean().required(),
    isForRoom: yup.boolean().required(),
  })
  .test(
    'servicetype-usage-not-selected',
    `${i18next.t('servicetype-usage-not-selected')}`,
    value =>
      value.isForDayVisitor ||
      value.isForSemidayVisitor ||
      value.isForOvernight ||
      value.isForSemidayOvernight ||
      value.isForDeparture ||
      value.isForSemidayDeparture ||
      value.isForPrevday ||
      value.isForNextday ||
      value.isForRoom,
  )
  .test(
    'servicetype-product-or-bundle',
    `${i18next.t('servicetype-product-or-bundle')}`,
    value => (value.productId || 0) > 0 || (value.productBundleId || 0) > 0,
  );

const facilitiesSchema = yup.object().shape({
  facilityId: yup.number().required(),
  isForDay: yup.boolean().required(),
  isForSemiday: yup.boolean().required(),
  includedCount: yup.number().required().label(i18next.t('servicetype-facility-includedcount')),
});

const validationSchema = yup
  .object()
  .shape({
    isCreate: yup.boolean().required(),
    isSystem: yup.boolean().required(),
    name: yup.string().required().label(i18next.t('servicetype-name')),
    sequence: yup.number().required().label(i18next.t('servicetype-sequence')),
    spaceId: yup.number().required().label(i18next.t('field-space')),
    sku: yup.string(),
    isPublished: yup.boolean().required(),
    minDayVisitors: yup.number().nullable().min(0),
    maxDayVisitors: yup.number().nullable().min(0),
    minOvernight: yup.number().nullable().min(0),
    maxOvernight: yup.number().nullable().min(0),
    minTotalGuests: yup.number().nullable().min(0),
    maxTotalGuests: yup.number().nullable().min(0),
    products: yup.array().required().of(productsSchema),
    facilities: yup.array().required().of(facilitiesSchema),
    addonServices: yup.array().required().of(
      yup.object().shape({
        approvalLimit: yup.number().nullable(),
        selectable: yup.boolean().required(),
        productId: yup.number().nullable(),
        facilityId: yup.number().nullable()
      })
    ),
    content: yup.array().required().of(ProductDetailsFlatContentSchema),
  })
  .test(
    'servicetype-products-or-facilities',
    `${i18next.t('servicetype-products-or-facilities')}`,
    value => value.products.length > 0 || value.facilities.length > 0,
  );

function ServiceTypeForm(props: ServiceTypeFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewQuery] = useLazyQuery(SERVICETYPE_VIEW_QUERY);
  const [updateMutateFunction] = useMutation(UPDATE_SERVICETYPE_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_SERVICETYPE_MUTATION);
  const [setTextMutateFunction] = useMutation(SET_PRODUCTDETAILS_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || (canEditAdminRecord(user, props.data) && (user.isSeminargo || !props.data.isSystem));
  const canEditProducts = canEdit;
  const canEditFacilities = props.data.id < 0 || user.isSeminargo || canEditAdminRecord(user, props.data);
  const canEditAddons = props.data.id < 0 || user.isSeminargo || canEditAdminRecord(user, props.data);
  const canEditText = canEdit;

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/servicetypes/${createdId}`);
  }, [createdId]);

  type ServiceTypeFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: ServiceTypeListOutput): ServiceTypeFormType => ({
    ...obj,
    isCreate: props.data.id > 0 ? false : true,
    spaceId: obj.space.id,
    products: obj.products.map(p => ({
      ...p,
      productId: p.product?.id,
      productBundleId: p.bundle?.id,
    })),
    facilities: obj.facilities.map(f => ({
      ...f,
      facilityId: f.facility.id,
    })),
    addonServices: obj.addons.map(a => ({
      approvalLimit: a.approvalLimit,
      selectable: a.selectable,
      productId: a.product?.id,
      facilityId: a.facility?.id
    })),
    content: obj.content.map(ProductDetailsFlatFromTypeToSchema),
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    getValues,
    formState: { errors: validationErrors, isDirty, isValidating, isSubmitting },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as ServiceTypeListOutput),
  });

  const {
    fields: productsFields,
    append: productsAppend,
    remove: productsRemove,
  } = useFieldArray({
    control,
    name: 'products',
  });
  const {
    fields: facilitiesFields,
    append: facilitiesAppend,
    remove: facilitiesRemove,
  } = useFieldArray({
    control,
    name: 'facilities',
  });
  const {
    fields: contentFields,
    append: contentAppend,
    remove: contentRemove,
  } = useFieldArray({
    control,
    name: 'content',
  });
  const {
    fields: addonServicesField,
    append: addonServicesAppend,
    remove: addonServicesRemove,
  } = useFieldArray({
    control,
    name: 'addonServices',
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = async (values: ServiceTypeFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.name,
              isSystem: values.isSystem,
              sequence: values.sequence,
              isPublished: !!values.isPublished,
              minDayVisitors: values.minDayVisitors,
              maxDayVisitors: values.maxDayVisitors,
              minOvernight: values.minOvernight,
              maxOvernight: values.maxOvernight,
              minTotalGuests: values.minTotalGuests,
              maxTotalGuests: values.maxTotalGuests,
              products: values.products
                .filter(p => p.productId)
                .map(p => ({
                  productId: p.productId!,
                  isForDayVisitor: !!p.isForDayVisitor,
                  isForDeparture: !!p.isForDeparture,
                  isForOvernight: !!p.isForOvernight,
                  isForSemidayVisitor: !!p.isForSemidayVisitor,
                  isForSemidayOvernight: !!p.isForSemidayOvernight,
                  isForSemidayDeparture: !!p.isForSemidayDeparture,
                  isForPrevday: !!p.isForPrevday,
                  isForNextday: !!p.isForNextday,
                  isForRoom: !!p.isForRoom,
                })),
              productBundles: values.products
                .filter(p => p.productBundleId)
                .map(p => ({
                  productBundleId: p.productBundleId!,
                  isForDayVisitor: !!p.isForDayVisitor,
                  isForDeparture: !!p.isForDeparture,
                  isForOvernight: !!p.isForOvernight,
                  isForSemidayVisitor: !!p.isForSemidayVisitor,
                  isForSemidayOvernight: !!p.isForSemidayOvernight,
                  isForSemidayDeparture: !!p.isForSemidayDeparture,
                  isForPrevday: !!p.isForPrevday,
                  isForNextday: !!p.isForNextday,
                  isForRoom: !!p.isForRoom,
                })),
              facilities: values.facilities.map(f => ({
                facilityId: f.facilityId,
                isForDay: !!f.isForDay,
                isForSemiday: !!f.isForSemiday,
                includedCount: f.includedCount,
              })),
              addons: values.addonServices.map(a => ({
                approvalLimit: a.approvalLimit,
                selectable: a.selectable,
                productId: a.productId,
                facilityId: a.facilityId
              })),
              content: values.content.map(ProductDetailsFlatFromSchemaToType).filter(t => canEditAdminSpaceId(user, t.spaceId)),
            },
          },
          update: cache => EVICT_SERVICETYPES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_SERVICETYPES_QUERIES(props.data.id, values.sku),
        });
        reset(toFormSchema((res.data!.updateServiceType || {}) as ServiceTypeListOutput));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.spaceId,
            data: {
              name: values.name,
              isSystem: values.isSystem,
              sequence: values.sequence,
              isPublished: !!values.isPublished,
              minDayVisitors: values.minDayVisitors,
              maxDayVisitors: values.maxDayVisitors,
              minOvernight: values.minOvernight,
              maxOvernight: values.maxOvernight,
              minTotalGuests: values.minTotalGuests,
              maxTotalGuests: values.maxTotalGuests,
              products: values.products
                .filter(p => p.productId)
                .map(p => ({
                  productId: p.productId!,
                  isForDayVisitor: !!p.isForDayVisitor,
                  isForDeparture: !!p.isForDeparture,
                  isForOvernight: !!p.isForOvernight,
                  isForSemidayVisitor: !!p.isForSemidayVisitor,
                  isForSemidayOvernight: !!p.isForSemidayOvernight,
                  isForSemidayDeparture: !!p.isForSemidayDeparture,
                  isForPrevday: !!p.isForPrevday,
                  isForNextday: !!p.isForNextday,
                  isForRoom: !!p.isForRoom,
                })),
              productBundles: values.products
                .filter(p => p.productBundleId)
                .map(p => ({
                  productBundleId: p.productBundleId!,
                  isForDayVisitor: !!p.isForDayVisitor,
                  isForDeparture: !!p.isForDeparture,
                  isForOvernight: !!p.isForOvernight,
                  isForSemidayVisitor: !!p.isForSemidayVisitor,
                  isForSemidayOvernight: !!p.isForSemidayOvernight,
                  isForSemidayDeparture: !!p.isForSemidayDeparture,
                  isForPrevday: !!p.isForPrevday,
                  isForNextday: !!p.isForNextday,
                  isForRoom: !!p.isForRoom,
                })),
              facilities: values.facilities.map(f => ({
                facilityId: f.facilityId,
                isForDay: !!f.isForDay,
                isForSemiday: !!f.isForSemiday,
                includedCount: f.includedCount,
              })),
              addons: values.addonServices.map(a => ({
                approvalLimit: a.approvalLimit,
                selectable: a.selectable,
                productId: a.productId,
                facilityId: a.facilityId
              })),
              content: values.content.map(ProductDetailsFlatFromSchemaToType),
            },
          },
          update: cache => EVICT_SERVICETYPES_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_SERVICETYPES_QUERIES(),
        });
        reset(toFormSchema((res.data!.createServiceType || {}) as ServiceTypeListOutput));
        setCreatedId(res.data!.createServiceType.id);
      }
      dispatchMessage(dispatch, i18next.t('servicetype-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };
  const onSubmitText = async (values: ServiceTypeFormType) => {
    if (props.data.id <= 0 || !canEditText) return;
    try {
      const res = await setTextMutateFunction({
        variables: {
          sku: props.data.sku,
          content: values.content.map(ProductDetailsFlatFromSchemaToType).filter(t => canEditAdminSpaceId(user, t.spaceId)),
        },
        update: cache => EVICT_SERVICETYPES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_SERVICETYPES_QUERIES(props.data.id, values.sku),
      });
      const serviceType = await viewQuery({ variables: { id: props.data.id } });
      reset(toFormSchema((serviceType.data!.viewServiceType || {}) as ServiceTypeListOutput));
      dispatchMessage(dispatch, i18next.t('servicetype-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const initialTabSelected = (location.hash === '#texts' && 3) || (location.hash === '#addons' && 2) || (location.hash === '#facilities' && 1) || undefined

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('servicetypes-list-page-title'), props.data])}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        {canEdit && <>
          <Grid item xs={12} sm={6}>
            <FormInputText name="name" control={control} label={i18next.t('servicetype-name')} required disabled={!canEdit} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SpaceSelectionInput
              checkAdmin
              name="spaceId"
              control={control}
              disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputNumber name="sequence" label={i18next.t('servicetype-sequence')} control={control} disabled={!canEdit} required />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInputText name="sku" control={control} label={i18next.t('servicetype-sku')} disabled />
          </Grid>
          {(user.isSeminargo || !props.data.isSystem) && <Grid item xs={12}>
            {user.isSeminargo && <FormInputCheckbox name="isSystem" control={control} label={i18next.t('servicetype-issystem')} disabled={!canEdit} />}
            <FormInputCheckbox name="isPublished" control={control} label={i18next.t('servicetype-ispublished')} disabled={!canEdit} />
          </Grid>}
        </>}
        <Grid item xs={12}>
          <CustomTabs
            initialSelected={initialTabSelected}
            headers={[
              ...(props.data.id > 0 && !props.data.validation.isComplete ? [i18next.t('servicetype-validation-tab')] : []),
              i18next.t('servicetype-products-tab'),
              i18next.t('servicetype-facilities-tab'),
              i18next.t('servicetype-addons-tab'),
              i18next.t('servicetype-text-tab'),
            ]}
            icons={[
              ...(props.data.id > 0 && !props.data.validation.isComplete ? [<ErrorIcon />] : []),
              validationErrors.products ? <ErrorIcon /> : undefined,
              validationErrors.facilities ? <ErrorIcon /> : undefined,
              undefined,
              validationErrors.content ? <ErrorIcon /> : undefined,
            ]}
            disabled={[
              ...(props.data.id > 0 && !props.data.validation.isComplete ? [false] : []),
              false,
              false,
              false,
              props.data.id > 0 ? false : true
            ]}
            hidden={[
              ...(props.data.id > 0 && !props.data.validation.isComplete ? [false] : []),
              !canEditProducts,
              !canEditFacilities,
              !canEditAddons,
              !canEditText
            ]}
            tabs={[
              ...(props.data.id > 0 && !props.data.validation.isComplete
                ? [
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>{i18next.t('servicetype-validation-hint')}</Typography>
                      </Grid>
                      {props.data.validation.missingProducts.length > 0 && (
                        <Grid item xs={12} sm={4}>
                          <SimpleTable
                            headers={[
                              i18next.t('servicetype-validation-products', {
                                productCount: `${props.data.validation.missingProducts.length}`,
                              }),
                            ]}
                            rows={props.data.validation.missingProducts.map(row => [row])}
                          />
                        </Grid>
                      )}
                      {props.data.validation.missingFacilities.length > 0 && (
                        <Grid item xs={12} sm={4}>
                          <SimpleTable
                            headers={[
                              i18next.t('servicetype-validation-facilities', {
                                facilityCount: `${props.data.validation.missingFacilities.length}`,
                              }),
                            ]}
                            rows={props.data.validation.missingFacilities.map(row => [row])}
                          />
                        </Grid>
                      )}
                    </Grid>,
                  ]
                : []),
              <>
                <SimpleTable
                  headers={[
                    '',
                    i18next.t('servicetype-products-product'),
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isfordayvisitor')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isforsemidayvisitor')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isforovernight')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isforsemidayovernight')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isforroom')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isfordeparture')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isforsemidaydeparture')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isforprevday')}</span>,
                    <span style={{ writingMode: 'vertical-rl' }}>{i18next.t('servicetype-products-isfornextday')}</span>,
                    '',
                  ]}
                  rows={productsFields.map((field, index) => {
                    const product = props.products.find(p => p.id === field.productId);
                    const productBundle = props.bundles.find(p => p.id === field.productBundleId);

                    return [
                      hintValidationAlert((validationErrors.products || [])[index]),
                      <>
                        {field.productId && (
                          <Link to={`/products/products/${field.productId}`}>
                            {product?.name}
                            {product?.extRefCode && ` (${product?.extRefCode})`}
                          </Link>
                        )}
                        {field.productBundleId && (
                          <Link to={`/products/bundles/${field.productBundleId}`}>
                            {productBundle?.name}
                            {productBundle?.extRefCode && ` (${productBundle?.extRefCode})`}
                          </Link>
                        )}
                      </>,
                      <FormInputCheckbox
                        key={`${field.id}.isForDayVisitor`}
                        name={`products.${index}.isForDayVisitor`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      <FormInputCheckbox
                        key={`${field.id}.isForSemidayVisitor`}
                        name={`products.${index}.isForSemidayVisitor`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      <FormInputCheckbox
                        key={`${field.id}.isForOvernight`}
                        name={`products.${index}.isForOvernight`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      <FormInputCheckbox
                        key={`${field.id}.isForSemidayOvernight`}
                        name={`products.${index}.isForSemidayOvernight`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      <FormInputCheckbox key={`${field.id}.isForRoom`} name={`products.${index}.isForRoom`} control={control} disabled={!canEditProducts} />,
                      <FormInputCheckbox
                        key={`${field.id}.isForDeparture`}
                        name={`products.${index}.isForDeparture`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      <FormInputCheckbox
                        key={`${field.id}.isForSemidayDeparture`}
                        name={`products.${index}.isForSemidayDeparture`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      <FormInputCheckbox
                        key={`${field.id}.isForPrevday`}
                        name={`products.${index}.isForPrevday`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      <FormInputCheckbox
                        key={`${field.id}.isForNextday`}
                        name={`products.${index}.isForNextday`}
                        control={control}
                        disabled={!canEditProducts}
                      />,
                      canEditProducts && (
                        <IconButton onClick={() => productsRemove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      ),
                    ];
                  })}
                />
                {canEditProducts && (
                  <>
                    <Select
                      value={0}
                      displayEmpty
                      onChange={event =>
                        event.target.value &&
                        productsAppend({
                          productId: event.target.value as number,
                          productBundleId: null,
                          isForDayVisitor: false,
                          isForDeparture: false,
                          isForOvernight: false,
                          isForSemidayVisitor: false,
                          isForSemidayOvernight: false,
                          isForSemidayDeparture: false,
                          isForPrevday: false,
                          isForNextday: false,
                          isForRoom: false,
                        })
                      }
                    >
                      {buildMenuItemsFromOptions(
                        buildGroupedSpaceSelectionOptions(
                          user,
                          filterForSelectableRecords(user, props.products.filter(p => getValues('products').findIndex(bi => bi.productId === p.id) < 0), getValues('spaceId'), false, true),
                          `${i18next.t('servicetype-product-add')}`,
                          0,
                        ),
                      )}
                    </Select>
                    <Select
                      value={0}
                      displayEmpty
                      onChange={event =>
                        event.target.value &&
                        productsAppend({
                          productId: null,
                          productBundleId: event.target.value as number,
                          isForDayVisitor: false,
                          isForDeparture: false,
                          isForOvernight: false,
                          isForSemidayVisitor: false,
                          isForSemidayOvernight: false,
                          isForSemidayDeparture: false,
                          isForPrevday: false,
                          isForNextday: false,
                          isForRoom: false,
                        })
                      }
                    >
                      {buildMenuItemsFromOptions(
                        buildGroupedSpaceSelectionOptions(
                          user,
                          filterForSelectableRecords(user, props.bundles.filter(p => getValues('products').findIndex(bi => bi.productBundleId === p.id) < 0), getValues('spaceId'), false, true),
                          `${i18next.t('servicetype-productbundle-add')}`,
                          0,
                        ),
                      )}
                    </Select>
                  </>
                )}
              </>,
              <>
                <SimpleTable
                  headers={[
                    '',
                    i18next.t('servicetype-facility-includedcount'),
                    i18next.t('servicetype-facility-isforday'),
                    i18next.t('servicetype-facility-isforsemiday'),
                    '',
                  ]}
                  rows={facilitiesFields.map((field, index) => {
                    const facility = props.facilities.find(p => p.id === field.facilityId);

                    return [
                      <Link to={`/products/facilities/${field.facilityId}`}>
                        {facility?.name}
                        {facility?.extRefCode && ` (${facility?.extRefCode})`}
                      </Link>,
                      <FormInputNumber
                        key={`${field.id}.includedCount`}
                        name={`facilities.${index}.includedCount`}
                        control={control}
                        label={i18next.t('servicetype-facility-includedcount')}
                        textFieldProps={{ fullWidth: false }}
                        required
                        disabled={!canEditFacilities}
                      />,
                      <FormInputCheckbox key={`${field.id}.isForDay`} name={`facilities.${index}.isForDay`} control={control} disabled={!canEditFacilities} />,
                      <FormInputCheckbox
                        key={`${field.id}.isForSemiday`}
                        name={`facilities.${index}.isForSemiday`}
                        control={control}
                        disabled={!canEditFacilities}
                      />,
                      canEditFacilities && (
                        <IconButton onClick={() => facilitiesRemove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      ),
                    ];
                  })}
                />
                {canEditFacilities && (
                  <Select
                    value={0}
                    displayEmpty
                    onChange={event =>
                      event.target.value &&
                      facilitiesAppend({
                        facilityId: event.target.value as number,
                        isForDay: true,
                        isForSemiday: true,
                        includedCount: 1,
                      })
                    }
                  >
                    {buildMenuItemsFromOptions(
                      buildGroupedSpaceSelectionOptions(
                        user,
                        filterForSelectableRecords(user, props.facilities.filter(p => getValues('facilities').findIndex(f => f.facilityId === p.id) < 0), getValues('spaceId'), false, true),
                        `${i18next.t('servicetype-facility-add')}`,
                        0,
                      ),
                    )}
                  </Select>
                )}
              </>,
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>{i18next.t('servicetype-addonservices-helper')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleTable
                    headers={[
                      i18next.t('servicetype-addonservices-product'),
                      i18next.t('servicetype-addonservices-approvallimit'),
                      i18next.t('servicetype-addonservices-selectable'),
                      '',
                    ]}
                    rows={addonServicesField.map((field, index) => {
                      const product = field.productId ? props.products.find(p => p.id === field.productId) : null
                      if (!product) return null

                      return [
                        <Link to={`/products/products/${field.productId}`}>
                          {product.name}
                          {product.extRefCode && ` (${product.extRefCode})`}
                        </Link>,
                        <FormInputNumber
                          key={`${field.id}.approvalLimit`}
                          name={`addonServices.${index}.approvalLimit`}
                          control={control}
                          disabled={!canEditAddons}
                          textFieldProps={{
                            fullWidth: undefined
                          }}
                        />,
                        <FormInputCheckbox
                          key={`${field.id}.selectable`}
                          name={`addonServices.${index}.selectable`}
                          control={control}
                          disabled={!canEditAddons}
                        />,                        
                        canEditAddons && (
                          <IconButton onClick={() => addonServicesRemove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        ),
                      ];
                    }).filter(r => r).map(r => r!)}
                  />
                  {canEditAddons && <Select
                    value={0}
                    displayEmpty
                    onChange={event =>
                      event.target.value &&
                      addonServicesAppend({
                        selectable: true,
                        productId: event.target.value as number,
                      })
                    }
                  >
                    {buildMenuItemsFromOptions(
                      buildGroupedSpaceSelectionOptions(
                        user,
                        filterForSelectableRecords(user, props.products.filter(p => !p.isDeduction && getValues('addonServices').findIndex(bi => bi.productId === p.id) < 0), getValues('spaceId'), false, true),
                        `${i18next.t('servicetype-addonservices-addproduct')}`,
                        0,
                      ),
                    )}
                  </Select>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleTable
                    headers={[
                      i18next.t('servicetype-addonservices-facility'),
                      i18next.t('servicetype-addonservices-approvallimit'),
                      i18next.t('servicetype-addonservices-selectable'),
                      '',
                    ]}
                    rows={addonServicesField.map((field, index) => {
                      const facility = field.facilityId ? props.facilities.find(p => p.id === field.facilityId) : null
                      if (!facility) return null

                      return [
                        <Link to={`/products/facilities/${field.facilityId}`}>
                          {facility?.name}
                          {facility?.extRefCode && ` (${facility?.extRefCode})`}
                        </Link>,
                        <FormInputNumber
                          key={`${field.id}.approvalLimit`}
                          name={`addonServices.${index}.approvalLimit`}
                          control={control}
                          disabled={!canEditAddons}
                          textFieldProps={{
                            fullWidth: undefined
                          }}
                        />,
                        <FormInputCheckbox
                          key={`${field.id}.selectable`}
                          name={`addonServices.${index}.selectable`}
                          control={control}
                          disabled={!canEditAddons}
                        />,
                        canEditAddons && (
                          <IconButton onClick={() => addonServicesRemove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        ),
                      ];
                    }).filter(r => r).map(r => r!)}
                  />
                  {canEditAddons && <Select
                    value={0}
                    displayEmpty
                    onChange={event =>
                      event.target.value &&
                      addonServicesAppend({
                        selectable: true,
                        facilityId: event.target.value as number,
                      })
                    }
                  >
                    {buildMenuItemsFromOptions(
                      buildGroupedSpaceSelectionOptions(
                        user,
                        filterForSelectableRecords(user, props.facilities.filter(p => getValues('addonServices').findIndex(bi => bi.facilityId === p.id) < 0), getValues('spaceId'), false, true),
                        `${i18next.t('servicetype-addonservices-addfacility')}`,
                        0,
                      ),
                    )}
                  </Select>}
                </Grid>                
              </Grid>,
              <ProductDetailsFlat
                control={control}
                baseSpaceId={watch('spaceId')}
                fields={contentFields}
                append={contentAppend}
                remove={contentRemove}
                watch={(index, field) => watch(`content.${index}.${field}`)}
                validationErrors={validationErrors}
              />
            ]}
          />
        </Grid>
        {(canEditProducts || canEditFacilities || canEditAddons) && (
          <Grid item xs={12}>
            {showValidationAlert(validationErrors)}
          </Grid>
        )}
        <Grid item xs={12}>
          {(canEditProducts || canEditFacilities || canEditAddons) && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {i18next.t('servicetype-save')}
            </Button>
          )}
          {props.data.id > 0 && !(canEditProducts || canEditFacilities || canEditAddons) && canEditText && (
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={!isDirty || isSubmitting || isValidating}
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  handleSubmit(onSubmitText)();
                }
              }}
            >
              {i18next.t('servicetype-save-text')}
            </Button>
          )}
          {props.data.id > 0 && <ServiceTypeDeleteButton id={props.data.id} spaceId={props.data.space.id} isSystem={props.data.isSystem} icon={false} />}
          {props.data.id > 0 && <ServiceTypeCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
        </Grid>
      </Grid>
    </>
  );
}

export default function ServiceType(props: ServiceTypeProps) {
  const productsQuery = useQuery(PRODUCTS_LIST_QUERY);
  const productBundlesQuery = useQuery(PRODUCTBUNDLES_LIST_QUERY);
  const facilitiesQuery = useQuery(FACILITIES_LIST_QUERY);
  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);
  const priceListsQuery = useQuery(PRICELISTS_LIST_QUERY);
  const serviceTypeQuery = useQuery(SERVICETYPE_VIEW_QUERY, {
    variables: { id: props.id },
  });

  const loading =
    productsQuery.loading ||
    productBundlesQuery.loading ||
    facilitiesQuery.loading ||
    hotelsQuery.loading ||
    priceListsQuery.loading ||
    serviceTypeQuery.loading;
  const error =
    productsQuery.error || productBundlesQuery.error || facilitiesQuery.error || hotelsQuery.error || priceListsQuery.error || serviceTypeQuery.error;

  if (loading) return <CircularProgress />;
  else if (error) return <RedirectError err={error} />;
  else
    return (
      <ServiceTypeForm
        data={serviceTypeQuery.data!.viewServiceType!}
        products={productsQuery.data!.listProducts}
        bundles={productBundlesQuery.data!.listProductBundles}
        facilities={facilitiesQuery.data!.listFacilities}
        hotels={hotelsQuery.data!.listHotels}
        priceLists={priceListsQuery.data!.listPriceLists}
      />
    );
}

export function ServiceTypeCreate(props: ServiceTypeCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  const productsQuery = useQuery(PRODUCTS_LIST_QUERY);
  const productBundlesQuery = useQuery(PRODUCTBUNDLES_LIST_QUERY);
  const facilitiesQuery = useQuery(FACILITIES_LIST_QUERY);
  const hotelsQuery = useQuery(HOTEL_LIST_QUERY);
  const priceListsQuery = useQuery(PRICELISTS_LIST_QUERY);

  const loading = productsQuery.loading || productBundlesQuery.loading || facilitiesQuery.loading || hotelsQuery.loading || priceListsQuery.loading;
  const error = productsQuery.error || productBundlesQuery.error || facilitiesQuery.error || hotelsQuery.error || priceListsQuery.error;

  if (loading) return <CircularProgress />;
  else if (error) return <RedirectError err={error} />;
  else
    return (
      <ServiceTypeForm
        data={{
          id: -1,
          isSystem: false,
          name: '',
          sequence: 0,
          isPublished: false,
          sku: '',
          addons: [],
          facilities: [],
          products: [],
          content: [],
          space: { id: initialSpaceId(user, filter) } as any,
          validation: null as any
        }}
        products={productsQuery.data!.listProducts}
        bundles={productBundlesQuery.data!.listProductBundles}
        facilities={facilitiesQuery.data!.listFacilities}
        hotels={hotelsQuery.data!.listHotels}
        priceLists={priceListsQuery.data!.listPriceLists}
      />
    );
}

interface ServiceTypeCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function ServiceTypeCopyButton(props: ServiceTypeCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = user.isSeminargo || (user.isAdmin && user.space && user.hasWidget)

  const [dialogOpen, setDialogOpen] = useState(false);
  const [spaceId, setSpaceId] = useState<number>(props.spaceId);

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_SERVICETYPE_MUTATION);

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id, spaceId: spaceId },
        update: cache => EVICT_SERVICETYPES_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_SERVICETYPES_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('servicetype-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon && !canEdit) return null;
  if (!user.hasWidget && !canEdit) return null;

  if (props.icon) {
    return (
      <>
        <IconButton disabled={!canEdit || copyMutateLoading} onClick={() => (user.spaces.length === 1 ? __do() : setDialogOpen(true))}>
          <Tooltip title={i18next.t('servicetype-copy')}>
            <ContentCopyIcon />
          </Tooltip>
        </IconButton>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('servicetype-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('servicetype-copy-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  } else {
    return (
      <>
        <Button
          sx={{ marginRight: 2 }}
          variant="contained"
          color="secondary"
          disabled={!canEdit || copyMutateLoading}
          startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
          onClick={() => (user.spaces.length === 1 ? __do() : setDialogOpen(true))}
        >
          {i18next.t('servicetype-copy')}
        </Button>
        <ConfirmationDialog open={dialogOpen} setOpen={setDialogOpen} title={i18next.t('servicetype-confirm-copy-title')} onConfirm={__do}>
          <SimpleDropdown
            name="spaceId"
            onChange={v => setSpaceId(v.target.value as number)}
            options={buildSpaceSelectionOptions(user, true)}
            label={i18next.t('servicetype-copy-space')}
            value={spaceId}
          />
        </ConfirmationDialog>
      </>
    );
  }
}

interface ServiceTypeDeleteButtonProps {
  id: number;
  spaceId: number;
  isSystem: boolean;
  icon: boolean;
}
export function ServiceTypeDeleteButton(props: ServiceTypeDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = (user.isSeminargo || !props.isSystem) && canEditAdminSpaceId(user, props.spaceId);

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_SERVICETYPE_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
            startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
            variant: 'contained',
            color: 'secondary',
          })}
      confirmationQuestion={i18next.t('servicetype-confirm-delete')}
      confirmationTitle={i18next.t('servicetype-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_SERVICETYPES_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_SERVICETYPES_QUERIES(),
          });
          navigate('/servicetypes');
          dispatchMessage(dispatch, i18next.t('servicetype-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('servicetype-delete')}
    </ConfirmationButton>
  );
}
