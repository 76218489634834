import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Alert, IconButton, Grid, CircularProgress, Toolbar, Container, Paper, Box, Typography } from '@mui/material';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';

import SimpleTable from 'components/table/SimpleTable';
import { VIEW_BOOKING_QUERY } from './gql';

import { OpenInNew } from '@mui/icons-material';
import { EOfferStatus, ViewBookingQuery } from '__generated__/graphql';

interface BookingProps {
  id: number;
}
interface BookingFormProps {
  data: NonNullable<ViewBookingQuery['viewBooking']>
}

function BookingForm(props: BookingFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>
          {i18next.t('booking-page-header')} / {props.data.title}
        </title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              Status: {props.data.status}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{props.data.title}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SimpleTable rows={props.data.offers.map((offer) => [
            <Link to={`/settings/hotels/${offer.hotel.id}`}>{offer.hotel.name}</Link>,
            `${i18next.t(`enums-EOfferStatus-${offer.status}`)}`,
            <IconButton onClick={() => navigate(offer.status === EOfferStatus.INVOICE ? `/invoices/${offer.id}` : `/offers/${offer.id}`)}><OpenInNew /></IconButton>,
          ])} headers={[
            i18next.t('booking-offers-header-hotel'),
            i18next.t('booking-offers-header-status'),
            '',
          ]} />
        </Grid>
      </Grid>
    </>
  );
}

export default function Booking(props: BookingProps) {
  const bookingQuery = useQuery(VIEW_BOOKING_QUERY, {
    variables: { id: props.id },
  });

  if (bookingQuery.loading) return <CircularProgress />;
  else if (bookingQuery.error) return <Alert severity="error">{bookingQuery.error.message}</Alert>
  else if (!bookingQuery.data?.viewBooking) return <Alert severity="error">{i18next.t('booking-not-found')}</Alert>
  else
    return (<>
      <Toolbar />
      <Container maxWidth="xl">
        <Paper>
          <Box p={2}>
            <BookingForm data={bookingQuery.data!.viewBooking}
            />
          </Box>
        </Paper>
      </Container>
    </>
    );
}