import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TableContainer, Paper } from '@mui/material';

interface SimpleTableCell {
  span?: number | null;
  cell: React.ReactNode | null;
}

interface SimpleTableProps {
  headers?: React.ReactNode[];
  rows: (React.ReactNode | SimpleTableCell | null)[][];
  hidden?: (boolean | null)[] | null;
  filter?: (boolean | null)[] | null;
  name?: string | null;
  rowIds?: any[] | null;
  fullWidth?: boolean;
  onRowClick?: (index: number) => void;
  tableProps?: {
    [key: string]: any;
  };
}

export default function SimpleTable(props: SimpleTableProps) {
  return (
    <TableContainer component={Paper} sx={{ width: props.fullWidth === undefined || props.fullWidth ? '100%' : 'fit-content' }}>
      <Table {...props.tableProps}>
        {props.headers && props.headers.length > 0 && (
          <TableHead>
            <TableRow>
              {props.headers
                .filter((h, index) => (props.hidden ? !props.hidden[index] : true))
                .map((h, i) => (
                  <TableCell key={`head_cell_${i}_${props.name}`}>{h}</TableCell>
                ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.rows
            .filter((row, index) => (props.filter ? props.filter[index] : true))
            .map((row, index) => (
              <TableRow
                key={`table_row_${(props.rowIds && props.rowIds[index]) || `${index}`}_${props.name}`}
                onClick={() => props.onRowClick?.(index)}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:nth-child(odd)': { backgroundColor: '#f9f9f9' },
                  cursor: props.onRowClick ? 'pointer' : 'default',
                  '&:hover': props.onRowClick ? {
                    backgroundColor: '#f0f0f0',
                    transition: 'background-color 0.2s ease',
                  } : {},
                }}
              >
                {row
                  .filter((r, index) => (props.hidden ? !props.hidden[index] : true))
                  .map((cell, index) => (
                    <TableCell
                      key={`table_row_cell_${index}_${props.name}`}
                      {...(cell && (cell as any)['span'] ? { colSpan: (cell as any).span } : {})}
                      sx={{ verticalAlign: 'top' }}
                    >
                      {(cell && (cell as any).cell) || cell}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
