import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHotelFilter, EPriceDisplayMode } from 'types';

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    value: null as IHotelFilter | null,
    priceDisplayMode: EPriceDisplayMode.GROSS,
  },
  reducers: {
    setFilter: (state, { payload }: PayloadAction<IHotelFilter>) => {
      state.value = payload;
    },
    resetFilter: state => {
      state.value = null;
    },
    setPriceDisplayMode: (state, action: PayloadAction<EPriceDisplayMode>) => {
      state.priceDisplayMode = action.payload;
    },
  },
});

export const { setFilter, resetFilter, setPriceDisplayMode } = filterSlice.actions;
export const filterReducer = filterSlice.reducer;

export default filterSlice;
