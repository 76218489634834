import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormTextInputProps } from './FormInputProps';
import { styled } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

const numberFormat = new Intl.NumberFormat();

const _showArrows = (textFieldProps: any) => {
  if (textFieldProps && textFieldProps.showArrows === false) return false;
  return true;
};

export const InputNumberTextField = (props: any) => {
  const UsedTextField = _showArrows(props.textFieldProps) ? TextField : StyledTextField;
  return <UsedTextField inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', type: 'number' }} {...props} />;
};

export const FormInputNumber = ({ name, control, label, disabled, readOnly, helperText, textFieldProps }: FormTextInputProps) => {
  const UsedTextField = _showArrows(textFieldProps) ? TextField : StyledTextField;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <UsedTextField
          helperText={error ? error.message : helperText ? helperText : undefined}
          error={!!error}
          onChange={event => {
            const { value } = event.target;
            if (value.length > 0) {
              const intValue = parseInt(value.toString().replace(/[^\d]/g, ''));
              if (isNaN(intValue)) onChange(null);
              else onChange(intValue);
            } else {
              onChange(null);
            }
          }}
          value={value !== null && value !== undefined ? numberFormat.format(value) : ''}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', type: 'number' }}
          fullWidth
          InputLabelProps={{
            ...(disabled ? { shrink: true } : {}),
            ...(textFieldProps && textFieldProps.InputLabelProps ? textFieldProps.InputLabelProps : {}),
          }}
          disabled={disabled || readOnly}
          label={label}
          {...textFieldProps}
        />
      )}
    />
  );
};
