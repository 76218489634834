import { useSelector } from 'react-redux';
import { EPriceDisplayMode, IHotelFilter } from '../types';

export const filterSelector = () => {
  return useSelector<any, IHotelFilter | null>(state => {
    return state.filter.value || null;
  });
};

export const priceDisplayModeSelector = () => {
  return useSelector<any, EPriceDisplayMode | null>(state => {
    return state.filter.priceDisplayMode || null;
  });
};