import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Alert, Grid, CircularProgress, Button, IconButton, Tooltip, Divider, InputLabel, Typography, MenuItem, Select } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { userSelector, canEditAdminRecord, canEditAdminSpaceId, initialSpaceId, filterForSelectableRecords } from 'helper/security';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputCountryDropdown, FormInputDropdown, SimpleDropdown } from 'components/form/FormInputDropdown';
import { FormInputMultiCheckbox } from 'components/form/FormInputMultiCheckbox';
import { FormInputRichText } from 'components/form/FormInputRichTextEditor';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import ConfirmationButton from 'components/dialogs/ConfirmationButton';

import {
  HOTEL_VIEW_QUERY,
  UPDATE_HOTEL_MUTATION,
  CREATE_HOTEL_MUTATION,
  DELETE_HOTEL_MUTATION,
  COPY_HOTEL_MUTATION,
  REFETCH_HOTELS_QUERIES,
  EVICT_HOTELS_QUERIES,
  COORDINATES_BY_ADDRESS_QUERY,
} from '../gql';
import { OFFERTEMPLATES_LIST_QUERY } from '../../content/gql';
import { SERVICETYPES_LIST_QUERY } from '../../products/gql';

import {
  HotelListOutput,
  EHotelWidgetStatusType,
  HotelMeetingRoomInput,
  EContentHotelAttribute,
  EContentHotelTextType,
  ListServiceTypesQuery,
  ViewHotelQuery,
  ListOfferTemplatesQuery,
  ECountryCode,
  ELanguageCode,
  EHotelListerStatusType,
  EClientGender,
  EContentHotelListerServiceTypeAssignment,
  EContentHotelContactType,
} from '__generated__/graphql';
import CustomTabs from 'components/Tabs';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormColorPicker } from 'components/form/FormInputColorPicker';
import { SpaceSelectionInput } from 'components/security/SpaceSelectionInput';
import { buildGroupedSpaceSelectionOptions, GroupedSpaceMultiCheckboxInput, GroupedSpaceSelectionInput } from 'components/security/GroupedSpaceSelectionInput';
import { filterSelector } from 'helper/filter';
import SimpleTable from 'components/table/SimpleTable';
import MediaSelectionDialog, { MediaSelectionDialogOutput } from 'components/dialogs/MediaSelectionDialog';
import { RedirectError } from 'pages/error';
import { FormInputPercentage } from 'components/form/FormInputPercentage';
import { formatDocumentTitle } from 'helper/usedocumenttitle';
import { isProductionRelease } from 'helper/deployment';
import { ICancellationRule, listDefaultClientCancellationRules, listPremiumClientCancellationRules } from 'semshared/offer/cancellation';
import { formatPercentage } from 'components/Percentage';
import { FormInputRadio } from 'components/form/FormInputRadio';

interface HotelProps {
  id: number;
}
interface HotelCreateProps { }
interface HotelFormProps {
  data: NonNullable<ViewHotelQuery['viewHotel']>;
  serviceTypes: ListServiceTypesQuery['listServiceTypes'];
  offerTemplates: ListOfferTemplatesQuery['listOfferTemplates'];
}

const validationSchema = yup.object().shape({
  isCreate: yup.boolean().required(),
  business: yup.object().shape({
    isPartner: yup.boolean(),
    name: yup.string().required().label(i18next.t('hotel-name')),
    spaceId: yup.number().required().label(i18next.t('field-space')),
    refCode: yup.string(),
    extRefCode: yup.string().nullable(),
    businessName: yup.string().nullable(),
    businessAddress1: yup.string().nullable(),
    businessAddress2: yup.string().nullable(),
    businessAddress3: yup.string().nullable(),
    businessAddress4: yup.string().nullable(),
    businessCountry: yup.mixed<ECountryCode>().oneOf(Object.values(ECountryCode)).required().label(i18next.t('hotel-businesscountry')),
    businessZip: yup.string().required().label(i18next.t('hotel-businesszip')),
    businessCity: yup.string().required().label(i18next.t('hotel-businesscity')),
    businessState: yup.string().nullable(),
    businessPhone: yup.string().nullable(),
    businessUrl: yup.string().nullable(),
    businessEmail: yup.string().nullable()
  }),
  notifications: yup.object().shape({
    notificationEmail: yup.string().nullable(),
    offerNotApprovedReminderHotel: yup.number().nullable(),
    offerNotApprovedReminderClient: yup.number().nullable(),
    depositReminderHotel: yup.number().nullable(),
    depositReminderCheckHotel: yup.number().nullable(),
  }),
  widget: yup.object().shape({
    showInWidget: yup.boolean().required(),
    widgetUrl: yup.string().nullable(),
    widgetColor: yup.string().required(),
    widgetColorEnabled: yup.boolean().required(),
    widgetPrivacyUrl: yup.string().nullable(),
    widgetTermsUrl: yup.string().nullable(),
    widgetNoRooms: yup.boolean(),
    widgetExpandedMode: yup.boolean(),
    widgetSlimMode: yup.boolean(),
    offerTemplateId: yup.number(),
    offerExpirationDays: yup.number().nullable(),
    widgetServiceTypeIds: yup.array().required().of(yup.number().required()),
    enableAvailabilityCheck: yup.boolean()
  }),
  lister: yup.object().shape({
    showInLister: yup.boolean().required(),
    slug: yup.string(),
    listerServiceTypeIdSmall: yup.number().nullable(),
    listerServiceTypeIdMedium: yup.number().nullable(),
    listerServiceTypeIdRegular: yup.number().nullable(),
    locationLatitude: yup.number().nullable(),
    locationLongitude: yup.number().nullable(),
    rating: yup.number().nullable().label(i18next.t('hotel-rating')),
    meetingRooms: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        area: yup.number().required(),
        capacityUForm: yup.number().nullable(),
        capacityTheater: yup.number().nullable(),
        capacityParlament: yup.number().nullable(),
        capacityCircle: yup.number().nullable(),
        capacityBankett: yup.number().nullable(),
        capacityCocktail: yup.number().nullable(),
        capacityBlock: yup.number().nullable(),
      }),
    ),
    medias: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
        previewUrl: yup.string().required(),
      }),
    ),
    stars: yup.string().nullable(),
    attributes: yup.array().of(yup.string().required()),
    cancellationRules: yup.array().of(
      yup.object().shape({
        daysToEvent: yup.number().required(),
        minCapacity: yup.number().nullable(),
        maxCapacity: yup.number().nullable(),
        minOvernight: yup.number().nullable(),
        maxOvernight: yup.number().nullable(),
        minTotalGuests: yup.number().nullable(),
        maxTotalGuests: yup.number().nullable(),
        toleranceRate: yup.number().nullable(),
        rate: yup.number().required()
      }),
    ),
    texts: yup.array().of(
      yup.object().shape({
        details: yup.string().nullable(),
        type: yup.mixed<EContentHotelTextType>().nullable(),
        language: yup.mixed<ELanguageCode>().nullable().oneOf(Object.values(ELanguageCode)),
      }),
    ),
    maxCapacityRooms: yup.number().nullable(),
    maxCapacityPeople: yup.number().nullable(),
  }),
  contacts: yup.array().of(
    yup.object().shape({
      id: yup.number().nullable(),
      firstname: yup.string().nullable(),
      lastname: yup.string().required().label(i18next.t('hotel-contact-lastname')),
      title: yup.string().nullable(),
      job: yup.string().nullable(),
      gender: yup.mixed<EClientGender>().nullable(),
      phone: yup.string().nullable(),
      email: yup.string().nullable(),
      contactType: yup.mixed<EContentHotelContactType>().required().label(i18next.t('hotel-contact-type')),
    }),
  ),
});

function HotelForm(props: HotelFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showMediaSelectionDialog, setShowMediaSelectionDialog] = useState(false);

  const [updateMutateFunction] = useMutation(UPDATE_HOTEL_MUTATION);
  const [createMutateFunction] = useMutation(CREATE_HOTEL_MUTATION);

  const user = userSelector()!;
  const canEdit = props.data.id < 0 || canEditAdminRecord(user, props.data);

  const [createdId, setCreatedId] = useState(0);
  useEffect(() => {
    if (createdId > 0) navigate(`/settings/hotels/${createdId}`);
  }, [createdId]);

  type HotelFormType = yup.InferType<typeof validationSchema>;

  const crToFormSchema = (cd: ICancellationRule) => ({
    daysToEvent: cd.daysToEvent,
    minCapacity: (cd.minCapacity !== null && cd.minCapacity !== undefined) ? Math.round(cd.minCapacity * 100) : null,
    maxCapacity: (cd.maxCapacity !== null && cd.maxCapacity !== undefined) ? Math.round(cd.maxCapacity * 100) : null,
    minOvernight: cd.minOvernight,
    maxOvernight: cd.maxOvernight,
    minTotalGuests: cd.minTotalGuests,
    maxTotalGuests: cd.maxTotalGuests,
    toleranceRate: (cd.toleranceRate !== null && cd.toleranceRate !== undefined) ? Math.round(cd.toleranceRate * 100) : null,
    rate: Math.round(cd.rate * 100)
  })

  const toFormSchema = (obj: HotelListOutput): HotelFormType => ({
    isCreate: props.data.id > 0 ? false : true,
    business: {
      isPartner: obj.isPartner,
      spaceId: obj.space.id,
      name: obj.name,
      refCode: obj.refCode,
      extRefCode: obj.extRefCode,
      businessName: obj.businessName,
      businessAddress1: obj.businessAddress1,
      businessAddress2: obj.businessAddress2,
      businessAddress3: obj.businessAddress3,
      businessAddress4: obj.businessAddress4,
      businessCountry: obj.businessCountry,
      businessZip: obj.businessZip,
      businessCity: obj.businessCity,
      businessState: obj.businessState,
      businessEmail: obj.businessEmail,
      businessPhone: obj.businessPhone,
      businessUrl: obj.businessUrl,
    },
    notifications: {
      notificationEmail: obj.notificationEmail,
      offerNotApprovedReminderHotel: obj.offerNotApprovedReminderHotel || null,
      offerNotApprovedReminderClient: obj.offerNotApprovedReminderClient || null,
      depositReminderHotel: obj.depositReminderHotel || null,
      depositReminderCheckHotel: obj.depositReminderCheckHotel || null,
    },
    widget: {
      widgetUrl: obj.widgetUrl || '',
      widgetColor: obj.widgetColor || '',
      widgetColorEnabled: obj.widgetColorEnabled || false,
      widgetPrivacyUrl: obj.widgetPrivacyUrl || '',
      widgetTermsUrl: obj.widgetTermsUrl || '',
      widgetNoRooms: obj.widgetNoRooms || false,
      widgetExpandedMode: obj.widgetExpandedMode || false,
      widgetSlimMode: obj.widgetSlimMode || false,
      offerTemplateId: obj.offerTemplate ? obj.offerTemplate.id : 0,
      offerExpirationDays: obj.offerExpirationDays || null,
      enableAvailabilityCheck: obj.enableAvailabilityCheck || false,
      widgetServiceTypeIds: obj.serviceTypes ? obj.serviceTypes.filter(s => s.forWidget).map(s => s.serviceType.id) : [],
      showInWidget: obj.showInWidget
    },
    lister: {
      rating: obj.rating,
      slug: obj.slug,
      listerServiceTypeIdSmall: (obj.serviceTypes && obj.serviceTypes.find(s => s.forLister && s.listerServiceTypeAssignment === EContentHotelListerServiceTypeAssignment.SMALL)?.serviceType?.id) || 0,
      listerServiceTypeIdMedium: (obj.serviceTypes && obj.serviceTypes.find(s => s.forLister && s.listerServiceTypeAssignment === EContentHotelListerServiceTypeAssignment.MEDIUM)?.serviceType?.id) || 0,
      listerServiceTypeIdRegular: (obj.serviceTypes && obj.serviceTypes.find(s => s.forLister && s.listerServiceTypeAssignment === EContentHotelListerServiceTypeAssignment.REGULAR)?.serviceType?.id) || 0,
      locationLatitude: obj.locationLatitude,
      locationLongitude: obj.locationLongitude,
      showInLister: obj.showInLister,
      meetingRooms: obj.meetingRooms
        ? obj.meetingRooms.map(mr => ({
          name: mr.name,
          area: mr.area,
          capacityUForm: mr.capacityUForm,
          capacityTheater: mr.capacityTheater,
          capacityParlament: mr.capacityParlament,
          capacityCircle: mr.capacityCircle,
          capacityBankett: mr.capacityBankett,
          capacityCocktail: mr.capacityCocktail,
          capacityBlock: mr.capacityBlock,
        }))
        : [],
      medias: obj.medias,
      stars: obj.attributes?.find(a => a.attribute.startsWith('CATEGORY_'))?.attribute || '',
      attributes: obj.attributes?.filter(a => !a.attribute.startsWith('CATEGORY_')).map(f => f.attribute) || [],
      cancellationRules: obj.cancellationRules?.map(cd => crToFormSchema(cd)),
      texts: obj.texts,
      maxCapacityRooms: obj.maxCapacityRooms || null,
      maxCapacityPeople: obj.maxCapacityPeople || null,
    },
    contacts: obj.contacts?.map(c => ({
      ...c,
      gender: c.gender || '' as EClientGender,
      contactType: c.contactType || EContentHotelContactType.MAIN_CONTACT
    }))
  });

  const formMethods = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as HotelListOutput),
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    watch,
    getValues,
    formState: { isDirty, isValidating, isSubmitting, errors: validationErrors },
  } = formMethods;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const {
    fields: meetingRoomsFields,
    append: meetingRoomsAppend,
    remove: meetingRoomsRemove,
  } = useFieldArray({
    control,
    name: 'lister.meetingRooms',
  });

  const {
    fields: mediasFields,
    append: mediasAppend,
    remove: mediasRemove,
  } = useFieldArray({
    control,
    name: 'lister.medias',
  });

  const {
    fields: cancellationRulesFields,
    append: cancellationRulesAppend,
    remove: cancellationRulesRemove,
  } = useFieldArray({
    control,
    name: 'lister.cancellationRules',
  });

  const {
    fields: textsFields,
    append: textsAppend,
    remove: textsRemove,
  } = useFieldArray({
    control,
    name: 'lister.texts',
  });

  const {
    fields: contactsFields,
    append: contactsAppend,
    remove: contactsRemove,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const getPreviewParams = () => {
    const formVals = watch();

    const params = [
      `&seminargoColorEnabled=${encodeURIComponent(formVals.widget.widgetColorEnabled)}`,
      `&seminargoColor=${encodeURIComponent(formVals.widget.widgetColor)}`,
      `&seminargoTermsUrl=${encodeURIComponent(formVals.widget.widgetTermsUrl || '')}`,
      `&seminargoPrivacyUrl=${encodeURIComponent(formVals.widget.widgetPrivacyUrl || '')}`,
      `&seminargoNoRooms=${encodeURIComponent(formVals.widget.widgetNoRooms || 'false')}`,
      `&seminargoExpandedMode=${encodeURIComponent(formVals.widget.widgetExpandedMode || 'false')}`,
      `&seminargoSlimMode=${encodeURIComponent(formVals.widget.widgetSlimMode || 'false')}`,
    ];
    return params.join('');
  };

  const onSubmit = async (values: HotelFormType) => {
    try {
      if (props.data.id > 0) {
        const res = await updateMutateFunction({
          variables: {
            id: props.data.id,
            data: {
              name: values.business.name,
              isPartner: !!values.business.isPartner,
              businessName: values.business.businessName || null,
              businessAddress1: values.business.businessAddress1 || null,
              businessAddress2: values.business.businessAddress2 || null,
              businessAddress3: values.business.businessAddress3 || null,
              businessAddress4: values.business.businessAddress4 || null,
              businessCountry: values.business.businessCountry,
              businessZip: values.business.businessZip,
              businessCity: values.business.businessCity,
              businessState: values.business.businessState,
              businessEmail: values.business.businessEmail || null,
              businessPhone: values.business.businessPhone || null,
              businessUrl: values.business.businessUrl || null,
              notificationEmail: values.notifications.notificationEmail || null,
              offerNotApprovedReminderHotel: values.notifications.offerNotApprovedReminderHotel || null,
              offerNotApprovedReminderClient: values.notifications.offerNotApprovedReminderClient || null,
              depositReminderHotel: values.notifications.depositReminderHotel || null,
              depositReminderCheckHotel: values.notifications.depositReminderCheckHotel || null,
              showInWidget: values.widget.showInWidget,
              widgetUrl: values.widget.widgetUrl || null,
              widgetColor: values.widget.widgetColor,
              widgetColorEnabled: values.widget.widgetColorEnabled,
              widgetPrivacyUrl: values.widget.widgetPrivacyUrl || null,
              widgetTermsUrl: values.widget.widgetTermsUrl || null,
              widgetNoRooms: values.widget.widgetNoRooms || false,
              widgetExpandedMode: values.widget.widgetExpandedMode || false,
              widgetSlimMode: values.widget.widgetSlimMode || false,
              offerTemplateId: values.widget.offerTemplateId && values.widget.offerTemplateId > 0 ? values.widget.offerTemplateId : null,
              offerExpirationDays: values.widget.offerExpirationDays || null,
              enableAvailabilityCheck: values.widget.enableAvailabilityCheck || false,
              showInLister: values.lister.showInLister,
              rating: values.lister.rating || null,
              slug: values.lister.slug,
              widgetServiceTypeIds: values.widget.widgetServiceTypeIds,
              listerServiceTypes: [
                values.lister.listerServiceTypeIdSmall ? { serviceTypeId: values.lister.listerServiceTypeIdSmall, serviceTypeAssignment: EContentHotelListerServiceTypeAssignment.SMALL } : null,
                values.lister.listerServiceTypeIdMedium ? { serviceTypeId: values.lister.listerServiceTypeIdMedium, serviceTypeAssignment: EContentHotelListerServiceTypeAssignment.MEDIUM } : null,
                values.lister.listerServiceTypeIdRegular ? { serviceTypeId: values.lister.listerServiceTypeIdRegular, serviceTypeAssignment: EContentHotelListerServiceTypeAssignment.REGULAR } : null,
              ].filter(l => l).map(l => l!),
              locationLatitude: values.lister.locationLatitude,
              locationLongitude: values.lister.locationLongitude,
              meetingRooms: values.lister.meetingRooms?.map((mr: HotelMeetingRoomInput) => ({
                name: mr.name,
                area: mr.area,
                capacityUForm: mr.capacityUForm,
                capacityTheater: mr.capacityTheater,
                capacityParlament: mr.capacityParlament,
                capacityCircle: mr.capacityCircle,
                capacityBankett: mr.capacityBankett,
                capacityCocktail: mr.capacityCocktail,
                capacityBlock: mr.capacityBlock,
              })),
              medias: values.lister.medias?.map((m: any) => ({
                id: m.id,
              })),
              attributes: [
                ...(values.lister.attributes || []).map((f: any) => ({
                  attribute: f,
                })),
                values.lister.stars ? { attribute: values.lister.stars! } : null
              ].filter(a => a).map(a => a!),
              cancellationRules: values.lister.cancellationRules?.map(cd => ({
                daysToEvent: cd.daysToEvent,
                minCapacity: (cd.minCapacity !== null && cd.minCapacity !== undefined) ? cd.minCapacity / 100 : null,
                maxCapacity: (cd.maxCapacity !== null && cd.maxCapacity !== undefined) ? cd.maxCapacity / 100 : null,
                minOvernight: cd.minOvernight,
                maxOvernight: cd.maxOvernight,
                minTotalGuests: cd.minTotalGuests,
                maxTotalGuests: cd.maxTotalGuests,
                toleranceRate: (cd.toleranceRate !== null && cd.toleranceRate !== undefined) ? cd.toleranceRate / 100 : null,
                rate: cd.rate / 100
              })) || [],
              texts: values.lister.texts?.map((t: any) => ({
                details: t.details,
                type: t.type,
                language: t.language,
              })),
              maxCapacityRooms: values.lister.maxCapacityRooms,
              maxCapacityPeople: values.lister.maxCapacityPeople,
              contacts: values.contacts?.map(c => ({
                id: c.id,
                firstname: c.firstname || null,
                lastname: c.lastname,
                title: c.title || null,
                job: c.job || null,
                gender: c.gender || null,
                phone: c.phone || null,
                email: c.email || null,
                contactType: c.contactType
              }))
            },
          },
          update: cache => EVICT_HOTELS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_HOTELS_QUERIES(props.data.id),
        });
        reset(toFormSchema((res.data!.updateHotel || {}) as HotelListOutput));
        dispatchMessage(dispatch, i18next.t('hotel-updated'));
      } else {
        const res = await createMutateFunction({
          variables: {
            spaceId: values.business.spaceId,
            data: {
              name: values.business.name,
              isPartner: !!values.business.isPartner,
              businessName: values.business.businessName || null,
              businessAddress1: values.business.businessAddress1 || null,
              businessAddress2: values.business.businessAddress2 || null,
              businessAddress3: values.business.businessAddress3 || null,
              businessAddress4: values.business.businessAddress4 || null,
              businessCountry: values.business.businessCountry,
              businessZip: values.business.businessZip,
              businessCity: values.business.businessCity,
              businessState: values.business.businessState,
              businessEmail: values.business.businessEmail || null,
              businessPhone: values.business.businessPhone || null,
              businessUrl: values.business.businessUrl || null,
              notificationEmail: values.notifications.notificationEmail || null,
              offerNotApprovedReminderHotel: values.notifications.offerNotApprovedReminderHotel || null,
              offerNotApprovedReminderClient: values.notifications.offerNotApprovedReminderClient || null,
              depositReminderHotel: values.notifications.depositReminderHotel || null,
              depositReminderCheckHotel: values.notifications.depositReminderCheckHotel || null,
              showInWidget: values.widget.showInWidget,
              widgetUrl: values.widget.widgetUrl || null,
              widgetColor: values.widget.widgetColor,
              widgetColorEnabled: values.widget.widgetColorEnabled,
              widgetPrivacyUrl: values.widget.widgetPrivacyUrl || null,
              widgetTermsUrl: values.widget.widgetTermsUrl || null,
              widgetNoRooms: values.widget.widgetNoRooms || false,
              widgetExpandedMode: values.widget.widgetExpandedMode || false,
              widgetSlimMode: values.widget.widgetSlimMode || false,
              offerTemplateId: values.widget.offerTemplateId && values.widget.offerTemplateId > 0 ? values.widget.offerTemplateId : null,
              offerExpirationDays: values.widget.offerExpirationDays || null,
              enableAvailabilityCheck: values.widget.enableAvailabilityCheck || false,
              showInLister: values.lister.showInLister,
              rating: values.lister.rating || null,
              contacts: values.contacts?.map(c => ({
                firstname: c.firstname || null,
                lastname: c.lastname,
                title: c.title || null,
                job: c.job || null,
                gender: c.gender || null,
                phone: c.phone || null,
                email: c.email || null,
                contactType: c.contactType
              })),
              maxCapacityRooms: values.lister.maxCapacityRooms,
              maxCapacityPeople: values.lister.maxCapacityPeople,
            },
          },
          update: cache => EVICT_HOTELS_QUERIES(cache),
          awaitRefetchQueries: true,
          refetchQueries: REFETCH_HOTELS_QUERIES(),
        });
        reset(toFormSchema((res.data!.createHotel || {}) as HotelListOutput));
        setCreatedId(res.data!.createHotel.id);
        dispatchMessage(dispatch, i18next.t('hotel-created'));
      }
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const [coordinatesByAddress] = useLazyQuery(COORDINATES_BY_ADDRESS_QUERY)

  return (
    <>
      <Helmet>
        <title>
          {formatDocumentTitle([i18next.t('hotels-list-page-title'), props.data])}
        </title>
      </Helmet>
      <FormProvider {...formMethods}>
        <UnsavedChangesPrompt isDirty={isDirty} unsavedPrefix={`/settings/hotels/${props.data.id}`} />
        <CustomTabs
          headers={[
            i18next.t('hotel-settings-tab'),
            i18next.t('hotel-contacts-tab'),
            i18next.t('hotel-notifications-tab'),
            i18next.t('hotel-lister-tab'),
            i18next.t('hotel-widget-tab'),
          ]}
          slugs={[
            'general',
            'contacts',
            'notifications',
            'epilo',
            'sem'
          ]}
          icons={[
            Object.keys(validationErrors.business || {}).length > 0 ? <ErrorIcon /> : undefined,
            validationErrors.contacts ? <ErrorIcon /> : undefined,
            Object.keys(validationErrors.notifications || {}).length > 0 ? <ErrorIcon /> : undefined,
            props.data.id < 0 || (Object.keys(validationErrors.lister || {}).length === 0 && (props.data.listerStatus === EHotelListerStatusType.READY || props.data.listerStatus === EHotelListerStatusType.INACTIVE)) ? undefined : <ErrorIcon />,
            props.data.id < 0 || (Object.keys(validationErrors.widget || {}).length === 0 && (props.data.widgetStatus === EHotelWidgetStatusType.READY || props.data.widgetStatus === EHotelWidgetStatusType.INACTIVE)) ? undefined : <ErrorIcon />,
          ]}
          hidden={[
            false,
            false,
            false,
            props.data.id < 0,
            props.data.id < 0
          ]}
          tabs={[
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormInputText name="business.name" control={control} label={i18next.t('hotel-name')} disabled={!canEdit} required />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputText name="business.refCode" control={control} label={i18next.t('hotel-refcode')} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputText name="business.extRefCode" control={control} label={i18next.t('hotel-extrefcode')} disabled />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <SpaceSelectionInput
                      checkAdmin
                      name="business.spaceId"
                      control={control}
                      disabled={!canEdit || props.data.id > 0 || user.isSingleAdminSpace}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputCheckbox
                      name="business.isPartner"
                      control={control}
                      label={i18next.t('hotel-ispartner')}
                      disabled={!canEdit || !user.isSeminargo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputText name="business.businessEmail" control={control} label={i18next.t('hotel-businessemail')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputText name="business.businessPhone" control={control} label={i18next.t('hotel-businessphone')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="business.businessUrl" control={control} label={i18next.t('hotel-businessurl')} disabled={!canEdit} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormInputText name="business.businessName" control={control} label={i18next.t('hotel-businessname')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="business.businessAddress1" control={control} label={i18next.t('hotel-businessaddress1')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="business.businessAddress2" control={control} label={i18next.t('hotel-businessaddress2')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="business.businessAddress3" control={control} label={i18next.t('hotel-businessaddress3')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="business.businessAddress4" control={control} label={i18next.t('hotel-businessaddress4')} disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInputText name="business.businessZip" control={control} label={i18next.t('hotel-businesszip')} disabled={!canEdit} required />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <FormInputText name="business.businessCity" control={control} label={i18next.t('hotel-businesscity')} disabled={!canEdit} required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputCountryDropdown
                      name="business.businessCountry"
                      control={control}
                      label={i18next.t('hotel-businesscountry')}
                      disabled={!canEdit}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormInputText name="business.businessState" control={control} label={i18next.t('hotel-businessstate')} disabled={!canEdit} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>,
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SimpleTable
                  name="contacts"
                  headers={[
                    i18next.t('hotel-contact-title'),
                    i18next.t('hotel-contact-firstname'),
                    i18next.t('hotel-contact-lastname'),
                    i18next.t('hotel-contact-gender'),
                    i18next.t('hotel-contact-job'),
                    i18next.t('hotel-contact-phone'),
                    i18next.t('hotel-contact-email'),
                    i18next.t('hotel-contact-type'),
                  ]}
                  rowIds={contactsFields.map((c, index: number) => c.id)}
                  rows={contactsFields.map((c, index: number) => [
                    <FormInputText name={`contacts.${index}.title`} control={control} disabled={!canEdit} />,
                    <FormInputText name={`contacts.${index}.firstname`} control={control} disabled={!canEdit} />,
                    <FormInputText name={`contacts.${index}.lastname`} control={control} disabled={!canEdit} />,
                    <FormInputDropdown name={`contacts.${index}.gender`} control={control} options={Object.keys(EClientGender).map(k => ({
                      value: k,
                      label: i18next.t(`enums-EClientGender-${k}`),
                    }))} disabled={!canEdit} />,
                    <FormInputText name={`contacts.${index}.job`} control={control} disabled={!canEdit} />,
                    <FormInputText name={`contacts.${index}.phone`} control={control} disabled={!canEdit} />,
                    <FormInputText name={`contacts.${index}.email`} control={control} disabled={!canEdit} />,
                    <FormInputDropdown name={`contacts.${index}.contactType`} control={control} options={Object.keys(EContentHotelContactType).map(k => ({
                      value: k,
                      label: i18next.t(`enums-EContentHotelContactType-${k}`),
                    }))} disabled={!canEdit} />,
                    <IconButton onClick={() => contactsRemove(index)}>
                      <DeleteIcon />
                    </IconButton>,
                  ])}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" startIcon={<AddIcon />} onClick={() => contactsAppend({
                  lastname: '',
                  gender: '' as EClientGender,
                  contactType: 'MAIN_CONTACT' as EContentHotelContactType,
                })}>
                  {i18next.t('hotel-contact-add')}
                </Button>
              </Grid>
            </Grid>,
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormInputText name="notifications.notificationEmail" control={control} label={i18next.t('hotel-notificationemail')} disabled={!canEdit} />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber
                  name="notifications.offerNotApprovedReminderHotel"
                  control={control}
                  label={i18next.t('hotel-offernotapprovedreminderhotel')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber
                  name="notifications.offerNotApprovedReminderClient"
                  control={control}
                  label={i18next.t('hotel-offernotapprovedreminderclient')}
                  disabled={!canEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber name="notifications.depositReminderHotel" control={control} label={i18next.t('hotel-depositreminderhotel')} disabled={!canEdit} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormInputNumber
                  name="notifications.depositReminderCheckHotel"
                  control={control}
                  label={i18next.t('hotel-depositremindercheckhotel')}
                  disabled={!canEdit}
                />
              </Grid>
            </Grid>,
            <Grid container spacing={3}>
              {props.data.id > 0 && (
                <Grid item xs={12}>
                  {props.data.listerStatus === EHotelListerStatusType.READY && (
                    <Alert severity="success">{i18next.t('hotel-lister-status-READY')}</Alert>
                  )}
                  {(props.data.listerStatus !== EHotelListerStatusType.READY && props.data.listerStatus !== EHotelListerStatusType.INACTIVE) && (
                    <Alert severity="warning">{i18next.t(`hotel-lister-status-${props.data.listerStatus}`)}</Alert>
                  )}
                </Grid>
              )}
              {user.isSeminargo && <>
                <Grid item xs={12}>
                  <FormInputCheckbox name="lister.showInLister" control={control} label={i18next.t('hotel-showinlister')} disabled={!canEdit} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">{i18next.t('hotel-lister-servicetypes')}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInputDropdown control={control}
                    name="lister.listerServiceTypeIdSmall"
                    label={i18next.t(`enums-EContentHotelServiceTypeAssignment-SMALL`)}
                    options={buildGroupedSpaceSelectionOptions(
                      user,
                      filterForSelectableRecords(user, props.serviceTypes, getValues('business.spaceId'), false, true),
                      `${i18next.t('hotel-lister-servicetype-add')}`,
                      0,
                    )}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInputDropdown control={control}
                    name="lister.listerServiceTypeIdMedium"
                    label={i18next.t(`enums-EContentHotelServiceTypeAssignment-MEDIUM`)}
                    options={buildGroupedSpaceSelectionOptions(
                      user,
                      filterForSelectableRecords(user, props.serviceTypes, getValues('business.spaceId'), false, true),
                      `${i18next.t('hotel-lister-servicetype-add')}`,
                      0,
                    )}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInputDropdown control={control}
                    name="lister.listerServiceTypeIdRegular"
                    label={i18next.t(`enums-EContentHotelServiceTypeAssignment-REGULAR`)}
                    options={buildGroupedSpaceSelectionOptions(
                      user,
                      filterForSelectableRecords(user, props.serviceTypes, getValues('business.spaceId'), false, true),
                      `${i18next.t('hotel-lister-servicetype-add')}`,
                      0,
                    )}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">{i18next.t('hotel-lister-settings')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText name="lister.rating" control={control} label={i18next.t('hotel-rating')} disabled={!canEdit} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText name="lister.slug" control={control} label={i18next.t('hotel-slug')} />
                </Grid>
              </>}
              <Grid item xs={12} sm={12}>
                <CustomTabs
                  headers={[
                    i18next.t('hotel-settings-location-tab'),
                    i18next.t('hotel-settings-texts-tab'),
                    i18next.t('hotel-settings-capacities-tab'),
                    i18next.t('hotel-settings-attributes-tab'),
                    i18next.t('hotel-settings-cancellation-tab'),
                    i18next.t('hotel-settings-media-tab'),
                  ]}
                  hidden={[false, ...(new Array(5).fill(isProductionRelease() ? true : false))]}
                  tabs={[
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <FormInputText name="lister.locationLatitude" control={control} label={i18next.t('hotel-location-latitude')} disabled={!canEdit} />

                        <Button color="secondary" variant='contained' onClick={async () => {
                          const lat = getValues('lister.locationLatitude');
                          const lon = getValues('lister.locationLongitude');
                          const res = await coordinatesByAddress({ variables: { address: `${getValues('business.businessAddress1')} ${getValues('business.businessAddress2') || ''} ${getValues('business.businessAddress3') || ''} ${getValues('business.businessAddress4') || ''} ${getValues('business.businessZip')} ${getValues('business.businessCity')}` } });
                          if (res.data && res.data.coordinatesByAddress) {
                            const { lat, lng } = res.data.coordinatesByAddress;
                            formMethods.setValue('lister.locationLatitude', lat, { shouldDirty: true });
                            formMethods.setValue('lister.locationLongitude', lng, { shouldDirty: true });
                          }
                        }}>{i18next.t('hotel-location-fetch-coords')}</Button>
                        <Button color="secondary" variant='contained' onClick={() => {
                          const lat = getValues('lister.locationLatitude');
                          const lon = getValues('lister.locationLongitude');
                          window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`);

                        }} >{i18next.t('hotel-location-openmap')}</Button>
                        <Alert sx={{ mt: 2 }} severity="info">Die Koordinaten werden basierend auf den hinterlegten Adressdaten im <Link to="general">Stammdaten-Tab</Link> ermittelt</Alert>
                      </Grid>
                      <Grid item xs={6}>
                        <FormInputText name="lister.locationLongitude" control={control} label={i18next.t('hotel-location-longitude')} disabled={!canEdit} />
                      </Grid>
                    </Grid>,
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <SimpleTable
                          name="texts"
                          headers={[i18next.t('hotel-settings-texts-type'), i18next.t('hotel-settings-texts-details'), i18next.t('hotel-settings-texts-language'), '']}
                          rowIds={textsFields.map((mr, index: number) => mr.id)}
                          rows={textsFields.map((mr, index: number) => [
                            <FormInputDropdown name={`lister.texts.${index}.type`} control={control} options={Object.keys(EContentHotelTextType).map(k => ({
                              value: k,
                              label: i18next.t(`enums-EContentHotelTextType-${k}`),
                            }))} disabled={!canEdit} />,
                            <FormInputRichText 
                              name={`lister.texts.${index}.details`} 
                              control={control} 
                              options={{
                                plugins: [''],
                                toolbar: 'bold italic',
                                menubar: false,
                                height: 300,
                              }}
                              disabled={!canEdit} 
                            />,
                            <FormInputText name={`lister.texts.${index}.language`} control={control} disabled={!canEdit} />,
                            <IconButton onClick={() => textsRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            textsAppend({
                              details: '',
                              type: EContentHotelTextType.HOTEL_DESCRIPTION,
                              language: ELanguageCode.de
                            })
                          }
                        >
                          {i18next.t('hotel-settings-texts-add')}
                        </Button>
                      </Grid>
                    </Grid>,
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6">{i18next.t('hotel-settings-meeting-rooms')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleTable
                          name="meetingRooms"
                          headers={[
                            i18next.t('hotel-settings-meeting-rooms-name'),
                            i18next.t('hotel-settings-meeting-rooms-area'),
                            i18next.t('hotel-settings-meeting-rooms-capacityUForm'),
                            i18next.t('hotel-settings-meeting-rooms-capacityTheater'),
                            i18next.t('hotel-settings-meeting-rooms-capacityParlament'),
                            i18next.t('hotel-settings-meeting-rooms-capacityCircle'),
                            i18next.t('hotel-settings-meeting-rooms-capacityBankett'),
                            i18next.t('hotel-settings-meeting-rooms-capacityCocktail'),
                            i18next.t('hotel-settings-meeting-rooms-capacityBlock'),
                            '',
                          ]}
                          rowIds={meetingRoomsFields.map((mr, index: number) => mr.id)}
                          rows={meetingRoomsFields.map((mr, index: number) => [
                            <FormInputText name={`lister.meetingRooms.${index}.name`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.area`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.capacityUForm`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.capacityTheater`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.capacityParlament`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.capacityCircle`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.capacityBankett`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.capacityCocktail`} control={control} disabled={!canEdit} />,
                            <FormInputText name={`lister.meetingRooms.${index}.capacityBlock`} control={control} disabled={!canEdit} />,
                            <IconButton onClick={() => meetingRoomsRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            meetingRoomsAppend({
                              name: '',
                              area: 0,
                            })
                          }
                        >
                          {i18next.t('hotel-settings-meeting-rooms-add')}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormInputNumber 
                          name="lister.maxCapacityRooms" 
                          control={control} 
                          label={i18next.t('hotel-settings-capacities-maxCapacityRooms')} 
                          disabled={!canEdit} 
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormInputNumber 
                          name="lister.maxCapacityPeople" 
                          control={control} 
                          label={i18next.t('hotel-settings-capacities-maxCapacityPeople')} 
                          disabled={!canEdit} 
                        />
                      </Grid>
                    </Grid>,
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormInputRadio
                          name="lister.stars"
                          control={control}
                          label={i18next.t(`enums-EContentHotelAttribute-CATEGORY`)}
                          options={[
                            { value: 'CATEGORY_ONE_STARS', label: i18next.t('enums-EContentHotelAttribute-CATEGORY_ONE_STARS') },
                            { value: 'CATEGORY_TWO_STARS', label: i18next.t('enums-EContentHotelAttribute-CATEGORY_TWO_STARS') },
                            { value: 'CATEGORY_THREE_STARS', label: i18next.t('enums-EContentHotelAttribute-CATEGORY_THREE_STARS') },
                            { value: 'CATEGORY_FOUR_STARS', label: i18next.t('enums-EContentHotelAttribute-CATEGORY_FOUR_STARS') },
                            { value: 'CATEGORY_FIVE_STARS', label: i18next.t('enums-EContentHotelAttribute-CATEGORY_FIVE_STARS') },
                            { value: 'CATEGORY_FOUR_STARS_SUPERIOR', label: i18next.t('enums-EContentHotelAttribute-CATEGORY_FOUR_STARS_SUPERIOR') },
                            { value: 'CATEGORY_FIVE_STARS_SUPERIOR', label: i18next.t('enums-EContentHotelAttribute-CATEGORY_FIVE_STARS_SUPERIOR') },
                          ]}
                          disabled={!canEdit}
                        />
                      </Grid>
                      {['LOCATION', 'SEMINAR', 'ROOM', 'DESIGN', 'ACTIVITY', 'CATERING', 'WELLNESS', 'HOTELFACILITY'].map(f => (
                        <Grid item xs={12} key={f}>
                          <FormInputMultiCheckbox
                            name="lister.attributes"
                            control={control}
                            label={i18next.t(`enums-EContentHotelAttribute-${f}`)}
                            options={Object.keys(EContentHotelAttribute).filter(k => k.startsWith(f)).map(k => ({
                              value: k,
                              label: i18next.t(`enums-EContentHotelAttribute-${k}`),
                            }))}
                            disabled={!canEdit}
                          />
                        </Grid>
                      ))}
                    </Grid>,
                    <Grid container spacing={3}>
                      {!watch('business.isPartner') && <>
                        <Grid item xs={12}>
                          {i18next.t('hotel-cancellation-details-nopartner')}
                        </Grid>
                      </>}
                      {watch('business.isPartner') && <>
                        <Grid item xs={12}>
                          {i18next.t('hotel-cancellation-details-partner')}
                        </Grid>
                      </>}
                      <Grid item xs={12}>
                        <SimpleTable
                          name="cancellationRules"
                          headers={[
                            i18next.t('hotel-cancellation-details-cancellation-days'),
                            //i18next.t('hotel-cancellation-details-cancellation-capacity'),
                            i18next.t('hotel-cancellation-details-cancellation-overnight'),
                            i18next.t('hotel-cancellation-details-cancellation-totalguests'),
                            i18next.t('hotel-cancellation-details-tolerance'),
                            i18next.t('hotel-cancellation-details-rate'),
                            '']}
                          rowIds={cancellationRulesFields.map((mr, index: number) => mr.id)}
                          rows={cancellationRulesFields.map((mr, index: number) => [
                            <Grid container spacing={1}>
                              <Grid item xs={12}><FormInputNumber required name={`lister.cancellationRules.${index}.daysToEvent`} control={control} disabled={!canEdit} /></Grid>
                            </Grid>,
                            //<Grid container spacing={1}>
                            //  <Grid item xs={6}><FormInputPercentage name={`lister.cancellationRules.${index}.minCapacity`} control={control} disabled={!canEdit} /></Grid>
                            //  <Grid item xs={6}><FormInputPercentage name={`lister.cancellationRules.${index}.maxCapacity`} control={control} disabled={!canEdit} /></Grid>
                            //</Grid>,
                            <Grid container spacing={1}>
                              <Grid item xs={6}><FormInputNumber name={`lister.cancellationRules.${index}.minOvernight`} control={control} disabled={!canEdit} /></Grid>
                              <Grid item xs={6}><FormInputNumber name={`lister.cancellationRules.${index}.maxOvernight`} control={control} disabled={!canEdit} /></Grid>
                            </Grid>,
                            <Grid container spacing={1}>
                              <Grid item xs={6}><FormInputNumber name={`lister.cancellationRules.${index}.minTotalGuests`} control={control} disabled={!canEdit} /></Grid>
                              <Grid item xs={6}><FormInputNumber name={`lister.cancellationRules.${index}.maxTotalGuests`} control={control} disabled={!canEdit} /></Grid>
                            </Grid>,
                            <FormInputPercentage name={`lister.cancellationRules.${index}.toleranceRate`} control={control} disabled={!canEdit} />,
                            <FormInputPercentage required name={`lister.cancellationRules.${index}.rate`} control={control} disabled={!canEdit} />,
                            <IconButton onClick={() => cancellationRulesRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={() => {
                          cancellationRulesAppend(crToFormSchema({ daysToEvent: 30, rate: 0 }));
                        }}>
                          {i18next.t('hotel-cancellation-details-add')}
                        </Button>
                        {(watch('lister.cancellationRules')?.length || 0) === 0 &&
                          <Button variant="contained" color="secondary" startIcon={<ContentCopyIcon />} onClick={() => {
                            listPremiumClientCancellationRules(watch('business.businessCountry')).forEach(cr => cancellationRulesAppend(crToFormSchema(cr)))
                          }}>
                            {i18next.t('hotel-cancellation-details-add-defaults')}
                          </Button>
                        }
                      </Grid>
                      <Grid item xs={12}>
                        {i18next.t('hotel-cancellation-details-defaultrules-premiumclient')}
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleTable
                          name="defaultCancellationRules"
                          headers={[
                            i18next.t('hotel-cancellation-details-cancellation-days'),
                            //i18next.t('hotel-cancellation-details-cancellation-capacity'),
                            i18next.t('hotel-cancellation-details-cancellation-overnight'),
                            i18next.t('hotel-cancellation-details-cancellation-totalguests'),
                            i18next.t('hotel-cancellation-details-tolerance'),
                            i18next.t('hotel-cancellation-details-rate')]}
                          rows={listPremiumClientCancellationRules(watch('business.businessCountry')).map((cr, index: number) => [
                            <>{cr.daysToEvent}</>,
                            //<>{cr.minCapacity ? formatPercentage(cr.minCapacity, 0) : '*'} - {cr.maxCapacity ? formatPercentage(cr.maxCapacity, 0) : '*'}</>,
                            <>{cr.minOvernight ? cr.minOvernight : '*'} - {cr.maxOvernight ? cr.maxOvernight : '*'}</>,
                            <>{cr.minTotalGuests ? cr.minTotalGuests : '*'} - {cr.maxTotalGuests ? cr.maxTotalGuests : '*'}</>,
                            <>{cr.toleranceRate && formatPercentage(cr.toleranceRate, 0)}</>,
                            <>{formatPercentage(cr.rate, 0)}</>
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {i18next.t('hotel-cancellation-details-defaultrules-defaultclient')}
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleTable
                          name="defaultCancellationRules"
                          headers={[
                            i18next.t('hotel-cancellation-details-cancellation-days'),
                            //i18next.t('hotel-cancellation-details-cancellation-capacity'),
                            i18next.t('hotel-cancellation-details-cancellation-overnight'),
                            i18next.t('hotel-cancellation-details-cancellation-totalguests'),
                            i18next.t('hotel-cancellation-details-tolerance'),
                            i18next.t('hotel-cancellation-details-rate')]}
                          rows={listDefaultClientCancellationRules(watch('business.businessCountry')).map((cr, index: number) => [
                            <>{cr.daysToEvent}</>,
                            //<>{cr.minCapacity ? formatPercentage(cr.minCapacity, 0) : '*'} - {cr.maxCapacity ? formatPercentage(cr.maxCapacity, 0) : '*'}</>,
                            <>{cr.minOvernight ? cr.minOvernight : '*'} - {cr.maxOvernight ? cr.maxOvernight : '*'}</>,
                            <>{cr.minTotalGuests ? cr.minTotalGuests : '*'} - {cr.maxTotalGuests ? cr.maxTotalGuests : '*'}</>,
                            <>{cr.toleranceRate && formatPercentage(cr.toleranceRate, 0)}</>,
                            <>{formatPercentage(cr.rate, 0)}</>
                          ])}
                        />
                      </Grid>
                    </Grid>,
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MediaSelectionDialog
                          open={showMediaSelectionDialog}
                          setOpen={setShowMediaSelectionDialog}
                          onSelect={(medias: MediaSelectionDialogOutput[]) => {
                            medias.forEach(media => {
                              if (!mediasFields.find(mr => mr.name === media.name)) {
                                mediasAppend(media);
                              }
                            });
                          }}
                        />
                        <SimpleTable
                          name="medias"
                          headers={[i18next.t('hotel-settings-medias-name'), '', '']}
                          rowIds={mediasFields.map((mr, index: number) => mr.id)}
                          rows={mediasFields.map((mr, index: number) => [
                            mr.name,
                            <img src={mr.previewUrl} width={150} />,
                            <IconButton onClick={() => mediasRemove(index)}>
                              <DeleteIcon />
                            </IconButton>,
                          ])}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setShowMediaSelectionDialog(true)}>
                          {i18next.t('hotel-settings-medias-add')}
                        </Button>
                      </Grid>
                    </Grid>,
                  ]}
                />
              </Grid>
            </Grid>,
            <Grid container spacing={3}>
            {props.data.id > 0 && (
              <Grid item xs={12}>
                {props.data.widgetStatus === EHotelWidgetStatusType.READY && (
                  <Alert severity="success">{i18next.t('hotel-widget-status-READY')}</Alert>
                )}
                {(props.data.widgetStatus !== EHotelWidgetStatusType.READY && props.data.widgetStatus !== EHotelWidgetStatusType.INACTIVE) && (
                  <Alert severity="warning">{i18next.t(`hotel-widget-status-${props.data.widgetStatus}`)}</Alert>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormInputCheckbox name="widget.showInWidget" control={control} label={i18next.t('hotel-showinwidget')} disabled={!canEdit} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{i18next.t('hotel-servicetypes-widget')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <GroupedSpaceMultiCheckboxInput
                name="widget.widgetServiceTypeIds"
                list={filterForSelectableRecords(user, props.serviceTypes, getValues('business.spaceId'), false, true)}
                control={control}
                mapElement={(s) => <Link to={`/servicetypes/${s.id}`}>{s.name}</Link>}
                disabled={!canEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTabs
                headers={[
                  i18next.t('hotel-sem-widget-settings'),
                  i18next.t('hotel-sem-offer-settings')
                ]}
                hidden={[false, false]}
                tabs={[
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputCheckbox name="widget.widgetColorEnabled" control={control} label={i18next.t('hotel-widget-color-enabled')} />
                      <FormColorPicker
                        name="widget.widgetColor"
                        control={control}
                        label={i18next.t('hotel-widget-color')}
                        required={watch('widget.widgetColorEnabled')}
                        helperText={i18next.t('hotel-widget-color-helper')}
                        disabled={!canEdit || !watch('widget.widgetColorEnabled')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={7}>
                      <InputLabel>{i18next.t('hotel-widget-code')}</InputLabel>
                      <pre>
                        {`<script defer="defer" src="${props.data.widgetBaseUrl!}/widget.js"></script>`}
                        <br />
                        {`<div id="seminargo-widget" data-hotel-ref="${props.data.refCode}"></div>`}
                      </pre>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name="widget.widgetPrivacyUrl"
                        control={control}
                        label={i18next.t('hotel-widget-privacy-url')}
                        helperText={i18next.t('hotel-widget-privacy-url-helper')}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name="widget.widgetTermsUrl"
                        control={control}
                        label={i18next.t('hotel-widget-terms-url')}
                        helperText={i18next.t('hotel-widget-terms-url-helper')}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SimpleDropdown
                        name="widget.widgetMode"
                        label={i18next.t('hotel-widget-display-mode')}
                        disabled={!canEdit}
                        value={watch('widget.widgetExpandedMode') ? 'expanded' : 'slim'}
                        options={[
                          { value: 'slim', label: i18next.t('enums-EHotelWidgetModeType-SLIM') },
                          { value: 'expanded', label: i18next.t('enums-EHotelWidgetModeType-EXPANDED') },
                        ]}
                        onChange={async (e) => {
                          formMethods.setValue('widget.widgetExpandedMode', e.target.value === 'expanded', { shouldValidate: true, shouldDirty: true, shouldTouch: true });
                          formMethods.setValue('widget.widgetSlimMode', e.target.value === 'slim', { shouldValidate: true, shouldDirty: true, shouldTouch: true });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputCheckbox
                        name="widget.widgetNoRooms"
                        control={control}
                        label={i18next.t('hotel-widget-no-rooms')}
                        helperText={i18next.t('hotel-widget-no-rooms-helper')}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormInputText
                        name="widget.widgetUrl"
                        control={control}
                        label={i18next.t('hotel-widgeturl')}
                        helperText={i18next.t('hotel-widgeturl-helper')}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {props.data.id > 0 && watch('widget.showInWidget') && (
                      <Grid item xs={12}>
                        <Typography variant="h6">{i18next.t('hotel-widget-preview')}</Typography>
                        <iframe src={`${props.data.widgetTestUrl!}${getPreviewParams()}`} style={{ width: '100%', height: '800px', border: 'none' }} />
                      </Grid>
                    )}
                  </Grid>,
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <GroupedSpaceSelectionInput
                        name="widget.offerTemplateId"
                        control={control}
                        label={i18next.t('hotel-offertemplate')}
                        list={filterForSelectableRecords(user, props.offerTemplates, getValues('business.spaceId'), false, true)}
                        disabled={!canEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormInputNumber name="widget.offerExpirationDays" control={control} label={i18next.t('hotel-offerexpirationdays')} disabled={!canEdit} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputCheckbox
                        name="widget.enableAvailabilityCheck"
                        control={control}
                        label={i18next.t('hotel-enableavailabilitycheck')}
                        disabled={!canEdit}
                      />
                    </Grid>
                  </Grid>
                ]}
              />
            </Grid>
          </Grid>,
          ]}
        />
      </FormProvider>
      {canEdit && (
        <Grid item xs={12}>
          <Button
            sx={{ marginRight: 2 }}
            variant="contained"
            startIcon={isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
            disabled={(props.data.id > 0 && !isDirty) || isSubmitting || isValidating}
            onClick={async () => {
              const valid = await trigger();
              if (valid) {
                handleSubmit(onSubmit)();
              }
            }}
          >
            {i18next.t('hotel-save')}
          </Button>
          {props.data.id > 0 && <HotelCopyButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
          {props.data.id > 0 && <HotelDeleteButton id={props.data.id} spaceId={props.data.space.id} icon={false} />}
        </Grid>
      )}
    </>
  );
}

export default function Hotel(props: HotelProps) {
  const hotelQuery = useQuery(HOTEL_VIEW_QUERY, {
    variables: { id: props.id },
  });
  const serviceTypesQuery = useQuery(SERVICETYPES_LIST_QUERY);
  const offerTemplatesQuery = useQuery(OFFERTEMPLATES_LIST_QUERY);

  const loading = hotelQuery.loading || serviceTypesQuery.loading || offerTemplatesQuery.loading;
  const error = hotelQuery.error || serviceTypesQuery.error || offerTemplatesQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <HotelForm
        data={hotelQuery.data!.viewHotel!}
        serviceTypes={serviceTypesQuery.data!.listServiceTypes}
        offerTemplates={offerTemplatesQuery.data!.listOfferTemplates}
      />
    );
}

export function HotelCreate(props: HotelCreateProps) {
  const filter = filterSelector();
  const user = userSelector()!;

  const serviceTypesQuery = useQuery(SERVICETYPES_LIST_QUERY);
  const offerTemplatesQuery = useQuery(OFFERTEMPLATES_LIST_QUERY);

  const loading = serviceTypesQuery.loading || offerTemplatesQuery.loading;
  const error = serviceTypesQuery.error || offerTemplatesQuery.error;

  if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <HotelForm
        data={
          {
            id: -1,
            name: '',
            refCode: '',
            businessCountry: ECountryCode.AT,
            widgetColor: '#000000',
            widgetExpandedMode: true,
            widgetServiceTypeIds: [],
            listerServiceTypes: [],
            showInLister: false,
            showInWidget: false,
            space: { id: initialSpaceId(user, filter) },
          } as any
        }
        serviceTypes={serviceTypesQuery.data!.listServiceTypes}
        offerTemplates={offerTemplatesQuery.data!.listOfferTemplates}
      />
    );
}

interface HotelCopyButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function HotelCopyButton(props: HotelCopyButtonProps) {
  const dispatch = useDispatch();

  const user = userSelector()!;
  const canEdit = user.isSeminargo

  const [copyMutateFunction, { loading: copyMutateLoading }] = useMutation(COPY_HOTEL_MUTATION);
  
  if (props.icon && !canEdit) return null;

  const __do = async () => {
    if (!canEdit) return;
    try {
      const res = await copyMutateFunction({
        variables: { id: props.id },
        update: cache => EVICT_HOTELS_QUERIES(cache),
        awaitRefetchQueries: true,
        refetchQueries: REFETCH_HOTELS_QUERIES(),
      });
      dispatchMessage(dispatch, i18next.t('hotel-copied'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  if (props.icon) {
    return (
      <IconButton disabled={!canEdit || copyMutateLoading} onClick={__do}>
        <Tooltip title={i18next.t('hotel-copy')}>
          <ContentCopyIcon />
        </Tooltip>
      </IconButton>
    );
  } else {
    return (
      <Button
        sx={{ marginRight: 2 }}
        variant="contained"
        color="secondary"
        disabled={!canEdit || copyMutateLoading}
        startIcon={copyMutateLoading ? <CircularProgress size={24} /> : <ContentCopyIcon />}
        onClick={__do}
      >
        {i18next.t('hotel-copy')}
      </Button>
    );
  }
}

interface HotelDeleteButtonProps {
  id: number;
  spaceId: number;
  icon: boolean;
}
export function HotelDeleteButton(props: HotelDeleteButtonProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = userSelector()!;
  const canEdit = user.isSeminargo;

  const [deleteMutateFunction, { loading: deleteMutateLoading }] = useMutation(DELETE_HOTEL_MUTATION);

  if (props.icon && !canEdit) return null;

  return (
    <ConfirmationButton
      sx={{ marginRight: 2 }}
      disabled={!canEdit || deleteMutateLoading}
      icon={props.icon}
      {...(props.icon
        ? {}
        : {
          startIcon: deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />,
          variant: 'contained',
          color: 'secondary',
        })}
      confirmationQuestion={i18next.t('hotel-confirm-delete')}
      confirmationTitle={i18next.t('hotel-confirm-delete-title')}
      onConfirm={async () => {
        if (!canEdit) return;
        try {
          const res = await deleteMutateFunction({
            variables: {
              id: props.id,
            },
            update: cache => EVICT_HOTELS_QUERIES(cache),
            awaitRefetchQueries: true,
            refetchQueries: REFETCH_HOTELS_QUERIES(),
          });
          navigate('/settings/hotels');
          dispatchMessage(dispatch, i18next.t('hotel-deleted'));
        } catch (err) {
          dispatchException(dispatch, err);
        }
      }}
    >
      {props.icon && (deleteMutateLoading ? <CircularProgress size={24} /> : <DeleteIcon />)}
      {!props.icon && i18next.t('hotel-delete')}
    </ConfirmationButton>
  );
}
