import { gql } from "__generated__";

export const BOOKING_LIST_FRAGMENT = gql(`
  fragment BookingListOutput on HLBookingListOutput {
    status
    title
    refCode
    id
    offers {
      id
      status
      hotel {
        id
        name
      }
    }
  }
`);

export const VIEW_BOOKING_QUERY = gql(`
  query ViewBooking($id: Int!) {
    viewBooking(id: $id) {
      ...BookingListOutput
    }
  }
`);