import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, ButtonGroup, Grid, CircularProgress } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import AddIcon from '@mui/icons-material/Add';

import PagedTable from 'components/table/PagedTable';
import { filterSelector } from 'helper/filter';
import { userSelector, hasAnyAdminSpace } from 'helper/security';

import { PRODUCTBUNDLES_LIST_QUERY } from '../gql';

import { ProductBundleCopyButton, ProductBundleDeleteButton } from './bundle';

export default function ProductBundlesList() {
  const navigate = useNavigate();
  const filter = filterSelector();
  const user = userSelector()!;

  const [query, { data, loading, error }] = useLazyQuery(PRODUCTBUNDLES_LIST_QUERY);

  return (
    <>
      <Helmet>
        <title>{i18next.t('productbundles-list-page-title')}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PagedTable
            filterKey={`${JSON.stringify(filter)}`}
            refetchPage={(skip, take) =>
              query({
                variables: {
                  spaceId: (filter && filter.spaceId) || null,
                  skip,
                  take,
                },
              })
            }
            rowsLoading={loading}
            rowsErr={error}
            headers={[
              i18next.t('productbundles-list-header-bundle'),
              i18next.t('productbundles-list-header-sku'),
              i18next.t('productbundles-list-header-recurring'),
              ...(user.isSingleSpace ? [] : [i18next.t('list-header-space')]),
              '',
            ]}
            rows={(data?.listProductBundles || []).map((row, index) => [
              <Link to={`/products/bundles/${row.id}`}>
                {row.name}
                {row.extRefCode && ` (${row.extRefCode})`}
              </Link>,
              row.sku,
              `${i18next.t('enums-EProductPayment-' + row.recurring)}`,
              ...(user.isSingleSpace ? [] : [row.space.name]),
              <ButtonGroup>
                <ProductBundleCopyButton id={row.id} spaceId={row.space.id} icon />
                <ProductBundleDeleteButton id={row.id} spaceId={row.space.id} isSystem={row.isSystem} icon />
              </ButtonGroup>,
            ])}
          />
        </Grid>
        {hasAnyAdminSpace(user) && (
          <Grid item xs={12}>
            <Button sx={{ marginRight: 2 }} variant="contained" startIcon={<AddIcon />} onClick={() => navigate('/products/bundles/_create')}>
              {i18next.t('productbundles-list-add')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
