import { NO_PRICE } from "../pricelist/quickprice";

export const formatOfferDay = (day: number) => (day < 0 ? 'Vortag' : 'Tag ' + (day + 1));

export const formatPrice = (price: number, language: string = 'de-AT', currency: string = 'EUR') => {
  if (isNaN(price)) return 'NaN';
  if (price === NO_PRICE) return '';

  const format = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
  });
  return format.format(price);
};
export const formatPercentage = (percentage: number, language: string = 'de-AT', precision: number = 2) => {
  if (isNaN(percentage)) return 'NaN';

  const format = new Intl.NumberFormat(language, {
    style: 'percent',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  });
  return format.format(percentage);
};
export const formatDate = (date: Date, language: string = 'de-AT') => {
  if (isNaN(date.getTime())) return 'NaD';

  const format = new Intl.DateTimeFormat(language);
  return format.format(date);
};
export const formatDateTime = (date: Date, language: string = 'de-AT') => {
  if (isNaN(date.getTime())) return 'NaD';

  const format = new Intl.DateTimeFormat(language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return format.format(date);
};

export const formatOccupancySeating = (seating: string, language: string = 'de-AT') => {
  if (seating === "UFORM") return "U-Form";
  if (seating === "BLOCK") return "Block";
  if (seating === "THEATER") return "Theater";
  if (seating === "PARLIAMENT") return "Parlament";
  if (seating === "BANKETT") return "Bankett";
  if (seating === "CIRCLE") return "Kreis";
  return ""
}

export const formatOccupancy = (occupancy: string, language: string = 'de-AT') => {
  if (occupancy === 'MORNING') return 'Vormittag';
  if (occupancy === 'AFTERNOON') return 'Nachmittag';
  if (occupancy === 'FULLDAY') return 'Ganztägig';
  return '';
}

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}