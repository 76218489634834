import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/client';
import { Alert, Grid, CircularProgress, Button } from '@mui/material';

import { useQuery, useMutation } from '@apollo/client';
import i18next from 'i18next';
import SaveIcon from '@mui/icons-material/Save';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validation';

import { dispatchException, dispatchMessage } from 'helper/snackbar';
import { userSelector, canEditAdminSpaceId } from 'helper/security';

import { FormInputText } from 'components/form/FormInputText';
import { UnsavedChangesPrompt } from 'components/form/UnsavedChangesPrompt';
import { FormInputCheckbox } from 'components/form/FormInputCheckbox';
import { FormColorPicker } from 'components/form/FormInputColorPicker';

import { SPACE_SETTINGS_CI_QUERY, UPDATE_SPACE_SETTINGS_CI_MUTATION } from '../gql';
import { CONTENTMEDIAS_LIST_QUERY } from '../../content/gql';

import { SpaceSettingsCiOutput, ContentMediaListOutput } from '__generated__/graphql';
import CustomTabs from 'components/Tabs';
import { GroupedSpaceSelectionInput } from 'components/security/GroupedSpaceSelectionInput';
import { filterSelector } from 'helper/filter';
import { RedirectError } from 'pages/error';

interface SpaceSettingsCiFormProps {
  spaceId: number;
  data: SpaceSettingsCiOutput;
  mediaData: ContentMediaListOutput[];
}

const validationSchema = yup.object().shape({
  logoFileName: yup.string().nullable(),
  logoFileNameEnabled: yup.boolean(),
  primaryColor: yup.string().nullable(),
  primaryColorEnabled: yup.boolean(),
  signature: yup.string().nullable(),
  signatureEnabled: yup.boolean(),
});

function SpaceSettingsCiForm(props: SpaceSettingsCiFormProps) {
  const dispatch = useDispatch();
  const user = userSelector()!;

  const [updateMutateFunction] = useMutation(UPDATE_SPACE_SETTINGS_CI_MUTATION);

  type SpaceSettingsCiFormType = yup.InferType<typeof validationSchema>;

  const toFormSchema = (obj: SpaceSettingsCiOutput): SpaceSettingsCiFormType => ({
    logoFileName: obj.logoFileName || '',
    logoFileNameEnabled: obj.logoFileNameEnabled || false,
    primaryColor: obj.primaryColor || '',
    primaryColorEnabled: obj.primaryColorEnabled || false,
    signature: obj.signature || null,
    signatureEnabled: obj.signatureEnabled || false,
  });

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
    context: { client: useApolloClient() },
    defaultValues: toFormSchema((props.data || {}) as SpaceSettingsCiOutput),
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    setValue,
    getValues,
    formState: { isDirty, isValidating, isSubmitting, isValid },
  } = formMethods;
  const formValues = getValues();

  useEffect(() => {
    if (user.isSingleSpace) {
      setValue('logoFileNameEnabled', true);
      setValue('primaryColorEnabled', true);
      setValue('signatureEnabled', true);
    }
  }, [user]);

  const onSubmit = async (values: SpaceSettingsCiFormType) => {
    try {
      const res = await updateMutateFunction({
        variables: {
          data: {
            spaceId: props.spaceId,
            logoFileName: values.logoFileName || null,
            logoFileNameEnabled: values.logoFileNameEnabled || false,
            primaryColor: values.primaryColor || null,
            primaryColorEnabled: values.primaryColorEnabled || false,
            signature: values.signature || null,
            signatureEnabled: values.signatureEnabled || false,
          },
        },
        awaitRefetchQueries: true,
      });
      reset(toFormSchema((res.data!.updateSpaceSettingsCi || {}) as SpaceSettingsCiOutput));
      dispatchMessage(dispatch, i18next.t('space-settings-ci-saved'));
    } catch (err) {
      dispatchException(dispatch, err);
    }
  };

  const selectedSpace = user.spaces.find(s => s.id === props.spaceId);

  return (
    <>
      <Helmet>
        <title>{i18next.t('space-settings-ci-page-title')}</title>
      </Helmet>
      <FormProvider {...formMethods}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <CustomTabs
          headers={[i18next.t('space-settings-ci-tab')]}
          tabs={[
            <Grid container spacing={3}>
              {!user.isSingleSpace && (
                <>
                  <Grid item xs={12}>
                    Einstellungen für den Verwaltungsbereich <strong>"{selectedSpace?.name}" </strong>
                  </Grid>
                  <Grid item xs={12}>
                    Einstellungen werden vom übergeordneten Verwaltungsbereich übernommen. Diese können hier überschrieben werden.
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <GroupedSpaceSelectionInput
                  name="logoFileName"
                  control={control}
                  label={i18next.t('space-settings-ci-logofilename')}
                  helperText={i18next.t('space-settings-ci-logofilename-helper')}
                  disabled={!formValues.logoFileNameEnabled || !canEditAdminSpaceId(user, props.spaceId)}
                  emptyValue=""
                  list={props.mediaData}
                  mapId={e => e.name}
                />
                {formValues.logoFileName && <img width={250} src={props.mediaData.find(m => formValues.logoFileName === m.name)?.previewUrl} />}
              </Grid>
              <Grid item xs={12} sm={6}>
                {!user.isSingleSpace && (
                  <FormInputCheckbox
                    name="logoFileNameEnabled"
                    control={control}
                    label={i18next.t('space-settings-ci-logofilename-enabled')}
                    helperText={i18next.t('space-settings-ci-logofilename-enabled-helper')}
                    disabled={!canEditAdminSpaceId(user, props.spaceId)}
                  />
                )}
              </Grid>
              <Grid item xs={12} />
              {user.hasWidget && <>
                <Grid item xs={12} sm={6}>
                  <FormColorPicker
                    name="primaryColor"
                    control={control}
                    label={i18next.t('space-settings-ci-primarycolor')}
                    helperText={i18next.t('space-settings-ci-primarycolor-helper')}
                    disabled={!formValues.primaryColorEnabled || !canEditAdminSpaceId(user, props.spaceId)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {!user.isSingleSpace && (
                    <FormInputCheckbox
                      name="primaryColorEnabled"
                      control={control}
                      label={i18next.t('space-settings-ci-primarycolor-enabled')}
                      helperText={i18next.t('space-settings-ci-primarycolor')}
                      disabled={!canEditAdminSpaceId(user, props.spaceId)}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name="signature"
                    control={control}
                    label={i18next.t('space-settings-ci-signature')}
                    helperText={i18next.t('space-settings-ci-signature-helper')}
                    disabled={!formValues.signatureEnabled || !canEditAdminSpaceId(user, props.spaceId)}
                    textFieldProps={{
                      multiline: true,
                      rows: 10,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {!user.isSingleSpace && (
                    <FormInputCheckbox
                      name="signatureEnabled"
                      control={control}
                      label={i18next.t('space-settings-ci-signature-enabled')}
                      disabled={!canEditAdminSpaceId(user, props.spaceId)}
                      helperText={i18next.t('space-settings-ci-signature')}
                    />
                  )}
                </Grid>
              </>}
            </Grid>,
          ]}
        />
      </FormProvider>
      <Grid item xs={12}>
        <Button
          sx={{ marginRight: 2 }}
          variant="contained"
          startIcon={isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
          disabled={isSubmitting || isValidating || !isValid || !canEditAdminSpaceId(user, props.spaceId)}
          onClick={async () => {
            const valid = await trigger();
            if (valid) {
              handleSubmit(onSubmit)();
            }
          }}
        >
          {i18next.t('space-settings-ci-save')}
        </Button>
      </Grid>
    </>
  );
}

export default function SpaceSettingsCi() {
  const filter = filterSelector();
  const user = userSelector()!;

  const spaceId = (filter && filter.spaceId) || user.space?.id || -1

  const ciQuery = useQuery(SPACE_SETTINGS_CI_QUERY, {
    variables: {
      spaceId: spaceId,
    },
    skip: spaceId <= 0,
    fetchPolicy: 'network-only',
  });
  const mediaQuery = useQuery(CONTENTMEDIAS_LIST_QUERY, {
    variables: {
      spaceId: spaceId,
    },
    skip: spaceId <= 0,
  });

  const loading = ciQuery.loading || mediaQuery.loading;
  const error = ciQuery.error || mediaQuery.error;

  if (spaceId <= 0) return <RedirectError err={'No Space selected'} />;
  else if (loading) return <CircularProgress />;
  else if (!loading && error) return <RedirectError err={error} />;
  else
    return (
      <SpaceSettingsCiForm
        spaceId={spaceId}
        data={ciQuery.data?.querySpaceSettingsCi as SpaceSettingsCiOutput}
        mediaData={mediaQuery.data?.listContentMedia as ContentMediaListOutput[]}
      />
    );
}
