import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography } from '@mui/material';
import { Button, Grid, Tab, Tabs, MenuItem, Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DifferenceIcon from '@mui/icons-material/Difference';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';

import { FormInputText } from 'components/form/FormInputText';
import { FormInputNumber } from 'components/form/FormInputNumber';
import { FormInputCurrency } from 'components/form/FormInputCurrency';
import { FormLabel } from 'components/form/FormLabel';
import { hintValidationAlert } from 'helper/form';
import { ContentBlockFormType, FormFieldsFormType, LineItemFormType, OfferVersionFormType, QuickPriceCalculatorContext } from './context';
import { FormInputRichText } from 'components/form/FormInputRichTextEditor';
import { formatOfferDay } from 'semshared/utils/format';
import { formatDate } from 'components/DateTime';
import { formatPercentage } from 'components/Percentage';
import { NO_PRICE } from 'semshared/pricelist/quickprice';
import { LabeledFrame } from 'components/LabeledFrame';
import { ITaxRate } from 'semshared/pricelist/taxselector';

interface IEditorAccordionProps {
  cssStyles: string;
  contentBlock: ContentBlockFormType;
  contentBlockIndex: number;
  showHtmlTab: boolean;
  fullWidth: boolean;
  control: Control<OfferVersionFormType>;
  disabled: boolean;
  canChangeItems: boolean;
  canChangePrices: boolean;
  canChangeStructure: boolean;
  isPricesNet: boolean;
  onLineItemChanged: (index: number, lineItemIndex: number) => void;
  onLineItemCopyPrices: (index: number, lineItemIndex: number) => void;
  onLineItemPriceComponentChanged: (index: number, lineItemIndex: number, componentIndex: number) => void;
  onLineItemSKUAdded: (cbIndex: number, sku: string) => LineItemFormType | null | undefined;
  onLineItemFreeAdded: (cbIndex: number, taxTypeId: number) => LineItemFormType | null | undefined;
  onTotalsChanged: () => void;
  onDelete: (index: number) => void;
}

interface IEditorAccordionLineItemProps {
  contentBlock: ContentBlockFormType;
  contentBlockIndex: number;
  fullWidth: boolean;
  lineItemIndex: number;
  control: Control<OfferVersionFormType>;
  disabled: boolean;
  canChangeItems: boolean;
  canChangePrices: boolean;
  canChangeStructure: boolean;
  isPricesNet: boolean;
  onLineItemRemoved: (index: number, lineItemIndex: number) => void;
  onLineItemChanged: (index: number, lineItemIndex: number) => void;
  onLineItemCopyPrices: (index: number, lineItemIndex: number) => void;
  onLineItemPriceComponentChanged: (index: number, lineItemIndex: number, componentIndex: number) => void;
}

interface IEditorAccordionFormFieldProps {
  cssStyles: string;
  contentBlock: ContentBlockFormType;
  contentBlockIndex: number;
  formFieldIndex: number;
  control: any;
  disabled?: boolean;
}

interface IEditorAccordionLineItemComponentProps {
  contentBlock: ContentBlockFormType;
  contentBlockIndex: number;
  lineItemIndex: number;
  componentIndex: number;
  control: any;
  disabled: boolean;
  isPricesNet: boolean;
  onLineItemChanged: (index: number, lineItemIndex: number) => void;
  onLineItemPriceComponentChanged: (index: number, lineItemIndex: number, componentIndex: number) => void;
}

function _taxRateNameLabelComponent(isPricesNet: boolean, component: { name: string, taxTypeId: number }, quickPriceCalculator: { getTaxRateForType: (taxTypeId: number) => ITaxRate | null } | undefined | null) {
  const taxRate = quickPriceCalculator && quickPriceCalculator.getTaxRateForType(component.taxTypeId)
  return taxRate ? taxRate.name : component.name
}
function _taxRateNameLabelItem(isPricesNet: boolean, component: { name: string, taxTypeId: number } | null | undefined, quickPriceCalculator: { getTaxRateForType: (taxTypeId: number) => ITaxRate | null } | undefined | null) {
  const taxRate = quickPriceCalculator && component && quickPriceCalculator.getTaxRateForType(component.taxTypeId)
  return `${isPricesNet ? i18next.t('offerblock-lineitem-priceitem-net') : i18next.t('offerblock-lineitem-priceitem-gross')}${taxRate ? ` ${formatPercentage(taxRate.rate)}` : ''}`
}

function EditorAccordionLineItem({
  contentBlock,
  contentBlockIndex,
  lineItemIndex,
  fullWidth,
  control,
  disabled,
  canChangeItems,
  canChangePrices,
  canChangeStructure,
  isPricesNet,
  onLineItemRemoved,
  onLineItemChanged,
  onLineItemCopyPrices,
  onLineItemPriceComponentChanged,
}: IEditorAccordionLineItemProps) {
  const { watch, getValues, formState } = useFormContext<OfferVersionFormType>();
  const quickPriceCalculator = useContext(QuickPriceCalculatorContext);

  const { fields: componentsFields } = useFieldArray({
    control,
    name: `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`,
  });

  const priceItemWatch = watch(
    `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceItem`,
    _.get(contentBlock, `lineItems[${lineItemIndex}].priceItem`),
  );
  const countWatch = watch(
    `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.count`,
    _.get(contentBlock, `lineItems[${lineItemIndex}].count`),
  );

  useEffect(() => {
    if (disabled) return
    if (!canChangeItems && !canChangePrices) return
    onLineItemChanged(contentBlockIndex, lineItemIndex);
  }, [priceItemWatch, countWatch]);

  return (<Grid container spacing={1}>
    <Grid item sm={1}>
      <FormInputNumber
        name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.count`}
        label={i18next.t('offerblock-lineitem-count')}
        control={control}
        required
        disabled={disabled || !canChangeItems}
      />
    </Grid>
    <Grid item sm={fullWidth ? 5 : 8}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          {hintValidationAlert(_.get(formState.errors, `contentBlocks[${contentBlockIndex}].lineItems[${lineItemIndex}]`))}
          <Typography>
            <FormLabel
              name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}`}
              control={control}
              render={(lineItem: LineItemFormType) => lineItem.header}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {!disabled && canChangeItems &&
              <Grid item xs={12}>
                <FormInputText
                  name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.header`}
                  label={i18next.t('offerblock-lineitem-header')}
                  control={control}
                  required
                  disabled={disabled || !canChangeItems}
                />
                <FormLabel
                  name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.sku`}
                  control={control}
                  render={sku => <>{sku && <Link to={`/products/sku/${sku}`}>{sku}</Link>}</>}
                />
              </Grid>
            }
            <Grid item xs={12}>
              <FormInputText
                name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.details`}
                label={i18next.t('offerblock-lineitem-details')}
                control={control}
                textFieldProps={{
                  multiline: true,
                  rows: 3,
                }}
                disabled={disabled}
              />
            </Grid>
            {!fullWidth && <Grid item xs={12}>
              {watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`).length > 1 &&
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  {componentsFields.map((c, ci) => (
                    <EditorAccordionLineItemComponent key={ci}
                      contentBlock={contentBlock}
                      contentBlockIndex={contentBlockIndex}
                      lineItemIndex={lineItemIndex}
                      componentIndex={ci}
                      control={control}
                      disabled={disabled || !canChangePrices}
                      isPricesNet={isPricesNet}
                      onLineItemChanged={onLineItemChanged}
                      onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                    />
                  ))}
                </Grid>
                <Grid item sm={6}>
                  <FormInputCurrency
                    name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceItem`}
                    label={isPricesNet ? i18next.t('offerblock-lineitem-priceitem-net') : i18next.t('offerblock-lineitem-priceitem-gross')}
                    currency="EUR"
                    emptyValue={NO_PRICE}
                    control={control}
                    disabled
                  />
                </Grid>
              </Grid>}
              {watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`).length <= 1 &&
                <FormInputCurrency
                  name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceItem`}
                  label={_taxRateNameLabelItem(isPricesNet, watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.0`), quickPriceCalculator)}
                  currency="EUR"
                  emptyValue={NO_PRICE}
                  control={control}
                  disabled={disabled || !canChangePrices}
                  required
                />}
            </Grid>}
            {!fullWidth && <Grid item xs={12}>
              {canChangePrices && !disabled && <Button
                onClick={() => onLineItemCopyPrices(contentBlockIndex, lineItemIndex)}
                startIcon={<DifferenceIcon />}
              >
                {i18next.t('offerblock-lineitem-copyprices')}
              </Button>}
              {canChangeStructure && !disabled && <Button
                onClick={() => onLineItemRemoved(contentBlockIndex, lineItemIndex)}
                startIcon={<DeleteIcon />}
              >
                {i18next.t('offerblock-lineitem-delete')}
              </Button>}
            </Grid>}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
    <Grid item sm={fullWidth ? 5 : 3}>
      {fullWidth && <Grid container spacing={1}>
        <Grid item sm={8}>
          {watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`).length > 1 && <LabeledFrame label={`${i18next.t('offerblock-lineitem-priceitem-header')}`}>
            <Grid container spacing={1}>
              <Grid item sm={6}>
                {componentsFields.map((c, ci) => (
                  <EditorAccordionLineItemComponent key={ci}
                    contentBlock={contentBlock}
                    contentBlockIndex={contentBlockIndex}
                    lineItemIndex={lineItemIndex}
                    componentIndex={ci}
                    control={control}
                    disabled={disabled || !canChangePrices}
                    isPricesNet={isPricesNet}
                    onLineItemChanged={onLineItemChanged}
                    onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                  />
              ))}
              </Grid>
              <Grid item sm={6}>
                <FormInputCurrency
                  name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceItem`}
                  label={isPricesNet ? i18next.t('offerblock-lineitem-priceitem-net') : i18next.t('offerblock-lineitem-priceitem-gross')}
                  currency="EUR"
                  emptyValue={NO_PRICE}
                  control={control}
                  disabled
                />
              </Grid>
            </Grid>
          </LabeledFrame>}
          {watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components`).length <= 1 &&
            <FormInputCurrency
              name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceItem`}
              label={_taxRateNameLabelItem(isPricesNet, watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.0`), quickPriceCalculator)}
              currency="EUR"
              emptyValue={NO_PRICE}
              control={control}
              disabled={disabled || !canChangePrices}
              required
            />
          }
        </Grid>
        {isPricesNet && <Grid item sm={4}>
          <FormInputCurrency
            name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceNet`}
            label={i18next.t('offerblock-lineitem-pricenet')}
            currency="EUR"
            emptyValue={NO_PRICE}
            control={control}
            disabled
          />
        </Grid>}
        {!isPricesNet && <Grid item sm={4}>
          <FormInputCurrency
            name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceGross`}
            label={i18next.t('offerblock-lineitem-pricegross')}
            currency="EUR"
            emptyValue={NO_PRICE}
            control={control}
            disabled
          />
        </Grid>}
      </Grid>}
      {!fullWidth && isPricesNet && <FormInputCurrency
        name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceNet`}
        label={i18next.t('offerblock-lineitem-pricenet')}
        currency="EUR"
        emptyValue={NO_PRICE}
        control={control}
        disabled
      />}
      {!fullWidth && !isPricesNet && <FormInputCurrency
        name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.priceGross`}
        label={i18next.t('offerblock-lineitem-pricegross')}
        currency="EUR"
        emptyValue={NO_PRICE}
        control={control}
        disabled
      />}
    </Grid>
    {fullWidth && <Grid item sm={1}>
      {canChangePrices && !disabled && <IconButton onClick={() => onLineItemCopyPrices(contentBlockIndex, lineItemIndex)}>
        <Tooltip title={i18next.t('offerblock-lineitem-copyprices')}>
          <DifferenceIcon />
        </Tooltip>
      </IconButton>}
      {canChangeStructure && !disabled && <IconButton onClick={() => onLineItemRemoved(contentBlockIndex, lineItemIndex)}>
        <Tooltip title={i18next.t('offerblock-lineitem-delete')}>
          <DeleteIcon />
        </Tooltip>
      </IconButton>}
    </Grid>}
  </Grid>);
}

function EditorAccordionFormField({ cssStyles, contentBlock, contentBlockIndex, formFieldIndex, control, disabled }: IEditorAccordionFormFieldProps) {
  const { watch, setValue, getValues } = useFormContext<OfferVersionFormType>();
  const type = watch(
    `contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`,
    _.get(contentBlock, `formFields[${formFieldIndex}].type`),
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {type === 'TEXTAREA_RICHTEXT' && (
          <FormInputRichText
            options={{
              content_style: cssStyles,
            }}
            placeholder={watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.placeholder`) || ''}
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.value`}
            label={watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.label`)}
            control={control}
            required={watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.required`) || false}
            disabled={disabled}
          />
        )}
        {type === 'TEXTAREA_PLAINTEXT' && (
          <FormInputText
            name={`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.defaultValue`}
            label={i18next.t('offerblock-formfield-defaultvalue')}
            control={control}
            required
            disabled={disabled}
          />
        )}
      </Grid>
    </Grid>
  );
}

function EditorAccordionLineItemComponent({
  contentBlock,
  contentBlockIndex,
  lineItemIndex,
  componentIndex,
  control,
  disabled,
  isPricesNet,
  onLineItemChanged,
  onLineItemPriceComponentChanged,
}: IEditorAccordionLineItemComponentProps) {
  const quickPriceCalculator = useContext(QuickPriceCalculatorContext);
  const { watch } = useFormContext<OfferVersionFormType>();
  const priceWatch = watch(
    `contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}.price`,
    _.get(contentBlock, `lineItems[${lineItemIndex}].components[${componentIndex}].price`),
  );

  useEffect(() => {
    if (disabled) return
    onLineItemPriceComponentChanged(contentBlockIndex, lineItemIndex, componentIndex);
    onLineItemChanged(contentBlockIndex, lineItemIndex);
  }, [priceWatch]);

  return (
    <FormInputCurrency
      key={`${watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}.name`)}`}
      name={`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}.price`}
      label={_taxRateNameLabelComponent(isPricesNet, watch(`contentBlocks.${contentBlockIndex}.lineItems.${lineItemIndex}.components.${componentIndex}`), quickPriceCalculator)}
      currency="EUR"
      emptyValue={NO_PRICE}
      control={control}
      required
      disabled={disabled}
    />
  );
}

export function EditorAccordion({
  cssStyles,
  contentBlock,
  contentBlockIndex,
  fullWidth,
  control,
  disabled,
  canChangePrices,
  canChangeStructure,
  canChangeItems,
  showHtmlTab,
  isPricesNet,
  onDelete,
  onLineItemFreeAdded,
  onLineItemSKUAdded,
  onLineItemChanged,
  onLineItemCopyPrices,
  onLineItemPriceComponentChanged,
  onTotalsChanged,
}: IEditorAccordionProps) {
  const [tabValue, setTabValue] = React.useState<string | null>('data');

  const quickPriceCalculator = useContext(QuickPriceCalculatorContext);
  const { watch, formState: { errors: validationErrors } } = useFormContext<OfferVersionFormType>();

  const {
    fields: lineItemsFields,
    append: lineItemsAppend,
    remove: lineItemsRemove,
    replace: lineItemsReplace,
  } = useFieldArray({
    control,
    name: `contentBlocks.${contentBlockIndex}.lineItems`,
  });

  const _reassignSequenceLineItems = (lineItems: LineItemFormType[]) => {
    lineItems.forEach((li, index) => {
      li.sequence = index + 1;
    });
    lineItemsReplace(lineItems);
  };

  const {
    fields: formFieldsFields,
    append: formFieldsAppend,
    remove: formFieldsRemove,
    replace: formFieldsReplace,
  } = useFieldArray({
    control,
    name: `contentBlocks.${contentBlockIndex}.formFields`,
  });

  const _reassignSequenceFormFields = (formFields: FormFieldsFormType[]) => {
    formFields.forEach((li, index) => {
      li.sequence = index + 1;
    });
    formFieldsReplace(formFields);
  };

  return (
    <div>
      <Accordion defaultExpanded={contentBlock.type === 'LINE_ITEMS'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          {hintValidationAlert(_.get(validationErrors, `contentBlocks[${contentBlockIndex}]`))}
          <Typography variant="h6">
            <FormLabel
              name={`contentBlocks.${contentBlockIndex}`}
              control={control}
              render={(contentBlock: ContentBlockFormType) => (
                <>
                  {contentBlock.type === 'CUSTOM_FORM' && (
                    <>
                      <span>
                        {i18next.t('offerblock-customform-header')} #{contentBlock.sequence}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${contentBlockIndex}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'PLAIN_HTML' && (
                    <>
                      <span>
                        {i18next.t('offerblock-plainhtml-header')} #{contentBlock.sequence}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${contentBlockIndex}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'LINE_ITEMS' && (
                    <>
                      <span>
                        {i18next.t('offerblock-lineitems-header-day')} {formatOfferDay(contentBlock.day!)} - {formatDate(moment(watch('startDate')).add(contentBlock.day!, 'days').toDate())} ({lineItemsFields.length})
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${contentBlockIndex}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'CANCELLATION_ITEMS' && (
                    <>
                      <span>
                        {i18next.t('offerblock-cancellationitems-header-phase', { rate: contentBlock.cancellationRule ? formatPercentage(contentBlock.cancellationRule.rate, 0) : '', daysToEvent: contentBlock.cancellationRule?.daysToEvent })} ({lineItemsFields.length})
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${contentBlockIndex}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                  {contentBlock.type === 'INPUT_DAYS' && (
                    <>
                      <span>
                        {i18next.t('offerblock-inputdays-header-day')} {formatOfferDay(contentBlock.day!)}
                      </span>
                      <br />
                      <span>
                        <em>{watch(`contentBlocks.${contentBlockIndex}.blockDescription`)}</em>
                      </span>
                    </>
                  )}
                </>
              )}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {contentBlock.type === 'CUSTOM_FORM' && (
                <>
                  {showHtmlTab && (
                    <Tabs value={tabValue}>
                      <Tab value="data" label={i18next.t('offerblock-customform-data-tab')} onClick={() => setTabValue('data')} />
                      <Tab value="template" label={i18next.t('offerblock-customform-template-tab')} onClick={() => setTabValue('template')} />
                    </Tabs>
                  )}
                  {tabValue === 'template' && showHtmlTab && (
                    <Grid container>
                      <Grid item xs={12}>
                        <FormInputText
                          name={`contentBlocks.${contentBlockIndex}.template`}
                          label={i18next.t('offerblock-customform-html')}
                          required
                          textFieldProps={{
                            multiline: true,
                            rows: 10,
                          }}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {tabValue === 'data' && (
                    <Grid container>
                      <Grid item xs={12}>
                        <DragDropContext
                          onDragEnd={result => {
                            if (result.destination && contentBlock.lineItems) {
                              const formFields = watch(`contentBlocks.${contentBlockIndex}.formFields`);
                              if (!formFields) return;
                              const [reorderedItem] = formFields.splice(result.source.index, 1);
                              formFields.splice(result.destination.index, 0, reorderedItem);
                              _reassignSequenceFormFields(formFields);
                            }
                          }}
                        >
                          <Droppable droppableId={`characters_${contentBlockIndex}`}>
                            {(provided: any) => (
                              <Grid container spacing={3} {...provided.droppableProps} ref={provided.innerRef} className={`characters_${contentBlockIndex}`}>
                                {formFieldsFields.map((li, formFieldIndex) => (
                                  <Draggable key={`${li.id}_s2`} draggableId={`${contentBlockIndex}_${formFieldIndex}_s2`} index={formFieldIndex} isDragDisabled={disabled}>
                                    {(provided: any) => (
                                      <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Accordion>
                                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography>
                                              {watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.label`)} -{' '}
                                              {watch(`contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.name`)}
                                              <br />
                                              <span>
                                                <em>
                                                  {i18next.t(
                                                    `enums-EOfferVersionContentBlockFormFieldType-${watch(
                                                      `contentBlocks.${contentBlockIndex}.formFields.${formFieldIndex}.type`,
                                                    )}`,
                                                  )}
                                                </em>
                                              </span>
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <EditorAccordionFormField
                                              cssStyles={cssStyles}
                                              contentBlock={contentBlock}
                                              contentBlockIndex={contentBlockIndex}
                                              formFieldIndex={formFieldIndex}
                                              control={control}
                                              disabled={disabled}
                                            />
                                          </AccordionDetails>
                                        </Accordion>
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </Grid>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {contentBlock.type === 'PLAIN_HTML' && showHtmlTab && (
                <FormInputRichText
                  options={{
                    content_style: cssStyles,
                  }}
                  name={`contentBlocks.${contentBlockIndex}.template`}
                  label={i18next.t('offerblock-plainhtml-html')}
                  control={control}
                  required={true}
                  disabled={disabled}
                />
              )}
              {contentBlock.type === 'INPUT_DAYS' && showHtmlTab && (
                <FormInputText
                  name={`contentBlocks.${contentBlockIndex}.template`}
                  label={i18next.t('offerblock-inputdays-html')}
                  required
                  textFieldProps={{
                    multiline: true,
                    rows: 10,
                  }}
                  control={control}
                  disabled={disabled}
                />
              )}
              {(contentBlock.type === 'LINE_ITEMS' || contentBlock.type === 'CANCELLATION_ITEMS') && (
                <>
                  {showHtmlTab && (
                    <Tabs value={tabValue}>
                      <Tab value="data" label={i18next.t('offerblock-lineitems-data-tab')} onClick={() => setTabValue('data')} />
                      <Tab value="template" label={i18next.t('offerblock-lineitems-template-tab')} onClick={() => setTabValue('template')} />
                    </Tabs>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {tabValue === 'template' && showHtmlTab && (
                        <>
                          <FormInputText
                            name={`contentBlocks.${contentBlockIndex}.template`}
                            label={i18next.t('offerblock-lineitems-html')}
                            required
                            textFieldProps={{
                              multiline: true,
                              rows: 10,
                            }}
                            control={control}
                            disabled={disabled}
                          />
                        </>
                      )}
                      {tabValue === 'data' && (
                        <Grid container>
                          <Grid item xs={12}>
                            <DragDropContext
                              onDragEnd={result => {
                                if (result.destination && contentBlock.lineItems) {
                                  const lineItems = watch(`contentBlocks.${contentBlockIndex}.lineItems`);
                                  if (!lineItems) return;
                                  const [reorderedItem] = lineItems.splice(result.source.index, 1);
                                  lineItems.splice(result.destination.index, 0, reorderedItem);
                                  _reassignSequenceLineItems(lineItems);
                                }
                              }}
                            >
                              <Droppable droppableId={`characters_${contentBlockIndex}`}>
                                {(provided: any) => (
                                  <Grid spacing={1} container {...provided.droppableProps} ref={provided.innerRef} className={`characters_${contentBlockIndex}`}>
                                    {lineItemsFields.map((li, lineItemIndex) => (
                                      <Draggable key={`${li.id}_s2`} draggableId={`${contentBlockIndex}_${lineItemIndex}_s2`} index={lineItemIndex} isDragDisabled={!canChangeItems || disabled}>
                                        {(provided: any) => (
                                          <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <EditorAccordionLineItem
                                              contentBlock={contentBlock}
                                              contentBlockIndex={contentBlockIndex}
                                              lineItemIndex={lineItemIndex}
                                              fullWidth={fullWidth}
                                              control={control}
                                              disabled={disabled}
                                              canChangePrices={canChangePrices}
                                              canChangeStructure={canChangeStructure}
                                              canChangeItems={canChangeItems}
                                              isPricesNet={isPricesNet}
                                              onLineItemRemoved={() => {
                                                lineItemsRemove(lineItemIndex);
                                                onTotalsChanged();
                                              }}
                                              onLineItemChanged={onLineItemChanged}
                                              onLineItemCopyPrices={onLineItemCopyPrices}
                                              onLineItemPriceComponentChanged={onLineItemPriceComponentChanged}
                                            />
                                          </Grid>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </Grid>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {canChangeItems && !disabled && <Grid item xs={12}>
                      <Select
                        value={0}
                        displayEmpty
                        onChange={event => {
                          if (!event.target.value) return;

                          const sku = `${event.target.value}`;
                          const li = onLineItemSKUAdded(contentBlockIndex, sku);
                          if (li) {
                            lineItemsAppend(li);
                            onTotalsChanged();
                          }
                        }}
                      >
                        <MenuItem value={0}>
                          <em>{i18next.t('offerblock-lineitem-addsku')}</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.allProductsDb &&
                          quickPriceCalculator.allProductsDb.map((p, index) => (
                            <MenuItem key={index} value={p.sku}>
                              {p.sku} {p.name}
                            </MenuItem>
                          ))}
                        <MenuItem value={''}>
                          <em>---------------------------</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.allBundlesDb &&
                          quickPriceCalculator.allBundlesDb.map((b, index) => (
                            <MenuItem key={index} value={b.sku}>
                              {b.sku} {b.name}
                            </MenuItem>
                          ))}
                        <MenuItem value={''}>
                          <em>---------------------------</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.allFacilitiesDb &&
                          quickPriceCalculator.allFacilitiesDb.map((f, index) => (
                            <MenuItem key={index} value={f.sku}>
                              {f.sku} {f.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <Select
                        value={0}
                        displayEmpty
                        onChange={event => {
                          if (!event.target.value) return;

                          const li = onLineItemFreeAdded(contentBlockIndex, event.target.value as any);
                          if (li) {
                            lineItemsAppend(li);
                            onTotalsChanged();
                          }
                        }}
                      >
                        <MenuItem value={0}>
                          <em>{i18next.t('offerblock-lineitem-addfree')}</em>
                        </MenuItem>
                        {quickPriceCalculator &&
                          quickPriceCalculator.taxRateSelector &&
                          quickPriceCalculator.taxRateSelector.taxTypes.map((t, index) => (
                            <MenuItem key={index} value={t.id}>
                              {t.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {canChangeItems && onDelete && ((contentBlock as any).day === -1 || (watch('contentBlocks').filter(c => (c.type === 'LINE_ITEMS' || c.type === 'CANCELLATION_ITEMS') && (c as any).day >= 0).length > 1 && contentBlockIndex === watch('contentBlocks').map(c => c.type === 'LINE_ITEMS' || c.type === 'CANCELLATION_ITEMS').lastIndexOf(true))) && (
                        <Button onClick={() => onDelete(contentBlockIndex)} startIcon={<DeleteIcon />}>
                          {i18next.t('offerblock-delete-day')}
                        </Button>
                      )}
                    </Grid>}
                  </Grid>
                </>
              )}

              {canChangeItems && !disabled && onDelete && contentBlock.type === 'PLAIN_HTML' && (
                <Button onClick={() => onDelete(contentBlockIndex)} startIcon={<DeleteIcon />}>
                  {i18next.t('offerblock-delete')}
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}